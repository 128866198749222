import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';



export default function PageTitle(props) {

  	return (
	    <Grid item xs={6} sx={{mt:3, mb:3, color:'#f89522'}}>
	    	<Typography variant="h4" sx={{mb:1,fontWeight:'bold'}}>{props.title}</Typography>
            <img src="/filiaire-orange-vert-bo.svg" height="30" alt="actifun"/>
        </Grid>
	);
}