import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    open: false,
    hasError: false,
    lang: 'fr',
    rows: [],
    site: {},
    loading: true,
    idSiteToDelete: null,
    openModalDeleteSite: false,
    nameSite: '' // Nom du site qui s'affiche dans l'input text `Nom du site` dans la page `Editer un site`
}

export const SiteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload
        },
        setHasError: (state, action) => {
            state.hasError = action.payload
        },
        setLang: (state, action) => {
           state.lang = action.payload
        },
        setRows: (state, action) => {
            state.rows = action.payload
        },
        setSite: (state, action) => {
            state.site = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIdSiteToDelete: (state, action) => {
            state.idSiteToDelete = action.payload
        },
        setOpenModalDeleteSite: (state, action) => {
            state.openModalDeleteSite = action.payload
        },
        setNameSite: (state, action) => {
            state.nameSite = action.payload
        }
    }
})

export const {
    setOpen,
    setHasError,
    setLang,
    setRows,
    setSite,
    setLoading,
    setOpenModalDeleteSite,
    setIdSiteToDelete,
    setNameSite
} = SiteSlice.actions
export default SiteSlice.reducer
