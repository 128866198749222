import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

export default function ModalDelete({openModal, handleClose, action, message}) {
  return (
    <Box>
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-describedby="alert-dialog-description">
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse'}}>
                <IconButton aria-label="delete" onClick={handleClose}>
                    <ClearIcon />
                </IconButton>
            </Box>
            <DialogContent sx={{ mt: 1, mb: 0, mx: 6 }}>
            <DialogContentText id="alert-dialog-description" sx={{typography: 'subtitle2'}}>
                {message}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Box sx={{ mb: 4, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button onClick={handleClose} color="primary" sx={{mr: 2, backgroundColor: '#dfdfdf !important'}}>
                    Non
                </Button>
                <Button onClick={action} color="primary" sx={{ml: 2}} autoFocus>
                    Oui, je suis sûr(e)
                </Button>                
            </Box>
            </DialogActions>
        </Dialog>        
    </Box>

  )
}
