import React, { useReducer, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import appconfig from "../../config/appconfig.json"
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { FormControl, TextField, Input, FormHelperText, Backdrop, CircularProgress, InputLabel, IconButton, Grid, Switch, Button, Box, Select, MenuItem, Rating, Typography } from '@mui/material'
import { getApparatus, getApparatusExercises, patchApparatus, createApparatus } from "../../api/ApparatusAPI"
import { deleteExerciseService} from "../../services/ExerciseServices"
import { upload as uploadFile } from "../../services/uploadService"
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AccessibleForward from '@mui/icons-material/AccessibleForward'
import NotAccessible  from '@mui/icons-material/NotAccessible'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PageTitle from '../PageTitle'
import {useNavigate } from "react-router-dom"
import { setHasError, setLang, setRows, setApparatus, setValidationErrors, setSelectedFile, setIsFilePicked, setPreviewImg, setName, setEnabled, setReference, setProductRange, resetApparatusData, setImageUrl } from '../../redux/Apparatus/ApparatusSlice'
import { store } from '../../redux/Store'
import { setBackdrop, setOpenAlert, addMessageAlert } from '../../redux/App/AppSlice'
import { isNotEmpty, removeErrorsFields } from '../../services/AppServices'
import ClearIcon from '@mui/icons-material/Clear'
import messagesconfig from "../../config/messagesconfig.json"
import TooltipGrid from '../shared/TooltipGrid'
import ModalDelete from '../../components/modal/ModalDelete'

const formReducer = (state, event) => {
 return {
   ...state,
   [event.name]: event.value
 }
}

export default function EditApparatusForm({context}) {

    let navigate = useNavigate();
    const params = useParams();

    const backdrop = useSelector((state)=>state.app.backdrop)
    const lang = useSelector((state)=>state.apparatus.lang)
    const rows = useSelector((state)=>state.apparatus.rows)
    const apparatus = useSelector((state)=>state.apparatus.apparatus)
    const validationErrors = useSelector((state)=>state.apparatus.validationErrors)
    const selectedFile = useSelector((state)=>state.apparatus.selectedFile)
    const isFilePicked = useSelector((state)=>state.apparatus.isFilePicked)
    const previewImg = useSelector((state)=>state.apparatus.previewImg)
    
    const name = useSelector((state)=>state.apparatus.name)
    const enabled = useSelector((state)=>state.apparatus.enabled)
    const reference = useSelector((state)=>state.apparatus.reference)
    const productRange = useSelector((state)=>state.apparatus.productRange)

    const [exerciseToDelete, setExerciseToDelete] = useState(null);
    const [openModalDeleteExercise, setOpenModalDeleteExercise] = useState(false); // Initialize state

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useReducer(formReducer, {});
    const actcolumns = [
        { 
            field: 'name', 
            headerName: 'Nom',
            renderHeader: (params) => (
                <TooltipGrid value={"Nom"} jsx={<strong>Nom</strong>} />
            ), 
            flex: 1,
            renderCell: (params)=>(
                <TooltipGrid value={params.row.name} jsx={<div>{params.row.name}</div>} />
            )
        },
        {
            field: 'level',
            headerName: 'Niveau de difficulté',
            flex: 1,
            renderHeader: (params) => (
                <TooltipGrid value={"Niveau de difficulté"} jsx={<strong>{"Niveau de difficulté"}</strong>} />
            ),
            renderCell: (params) => (
                <Rating max={3} name="read-only" value={params.value} readOnly />
            ),
        },
        {
            field: 'picture',
            headerName: 'Muscles solicités',
            flex: 1,
            align: 'center',
            renderHeader: (params) => (
                <TooltipGrid value={"Muscles sollicités"} jsx={<strong>{"Muscles sollicités"}</strong>} />
            ),
            renderCell: (params) => (
                <img alt="muscles" width='20%' src={params.value} />
            ),
        }, 
        { 
            field: 'inclusive',
            headerName: 'Inclusif',
            flex: 1,
            align: 'center',
            renderHeader: (params) => (
                <TooltipGrid value={"Inclusif"} jsx={<strong>{"Inclusif"}</strong>} />
            ),
            renderCell: (params) => {
                if(params.value)
                    return <AccessibleForward />
                else
                    return <NotAccessible />
            }
        },
        { 
            field: 'rep',
            headerName: 'Répétitions',
            flex: 1,
            align: 'center',
            renderHeader: (params) => (
                <TooltipGrid value={"Répétition"} jsx={<strong>{"Répétition"}</strong>} />
            ),
            renderCell: (params) => {
                return params.row.rep ? 'OUI' : 'NON'
            }
        },
        { 
            field: 'pointsByRep',
            headerName: 'Points par répétition',
            flex: 1,
            align: 'center',
            renderHeader: (params) => (
                <TooltipGrid value={"Points par répétition"} jsx={<strong>{"Points par répétition"}</strong>} />
            ),
            renderCell: (params) => {
                return valueOrClearIcon(params.row.pointsByRep)
            }
        },
        { 
            field: 'maxTime',
            headerName: 'Temps maximum',
            flex: 1,
            align: 'center',
            renderHeader: (params) => (
                <TooltipGrid value={"Temps maximum"} jsx={<strong>{"Temps maximum"}</strong>} />
            ),
            renderCell: (params) => {
                return valueOrClearIcon(params.row.maxTime)
            }
        },
        { 
            field: 'pointsByTime',
            headerName: 'Points par temps passé',
            flex: 1,
            align: 'center',
            renderHeader: (params) => (
                <TooltipGrid value={"Points par temps passé"} jsx={<strong>{"Points par temps passé"}</strong>}/>
            ),
            renderCell: (params) => {
                return valueOrClearIcon(params.row.pointsByTime)
            }
        },
        {
            field: 'enabled',
            headerName: 'Actif',
            flex: 1,
            renderHeader: (params) => (
                <TooltipGrid value={"Actif"} jsx={<strong>{"Actif"}</strong>}/>
            ),
            renderCell: (params) => {
                if(params.value)
                    return <CheckCircleOutlinedIcon color="success" />
                else
                    return <CancelOutlinedIcon color="error" />
            },
        },
       {
            field: 'delete', headerName: 'Supprimer', flex: 1,
            renderHeader: () => (
                <TooltipGrid value={"Supprimer"} jsx={<strong>{"Supprimer"}</strong>} />
            ),
            renderCell: (params) => {
                return <IconButton aria-label="delete" onClick={(e) => {
                    e.stopPropagation()
                           setExerciseToDelete(params.row.id);
                        handleClickOpen();

                }}>
                    <ClearIcon sx={{ color: 'red' }} />
                </IconButton>
            }
        },
         
    ];

    const valueOrClearIcon = (value) => {
        return value === 0 || !isNotEmpty(value) ? <ClearIcon sx={{color: 'red'}}/> : value
    }

    let previewImage = (event) => {

        let uploadedFile = event.target.files[0];

        if(uploadedFile && uploadedFile['type'].split('/')[0] === 'image')
        {
            store.dispatch(setPreviewImg(URL.createObjectURL(uploadedFile)))
            store.dispatch(setSelectedFile(uploadedFile))
            store.dispatch(setIsFilePicked(true))
        }
        else
        {
            let error = validationErrors;
            error["picture"] = "Mauvais format de fichier.";
            store.dispatch(setValidationErrors(error))
            store.dispatch(setPreviewImg(apparatus.picture ?? ""))
            store.dispatch(setSelectedFile(null))
            store.dispatch(setIsFilePicked(false))
        }
    }
    
    useEffect(() => {

        async function getCurrentApparatus() {

            store.dispatch(setBackdrop(true))

            const app = await getApparatus(params.id, lang)
            const exo = await getApparatusExercises(params.id, lang)     

            store.dispatch(setPreviewImg(app.picture))
            store.dispatch(setApparatus(app))
            store.dispatch(setRows(exo))

            setFormData({name: "name",value: app.name});
            setFormData({name: "enabled",value: app.enabled});
            setFormData({name: "reference",value: app.reference});
            setFormData({name: "productRange",value: app.productRange});
            setFormData({name: "imageUrl",value: app.imageUrl});
            setFormData({name: "picture",value: app.picture});

            store.dispatch(setName(app.name))
            store.dispatch(setEnabled(app.enabled))
            store.dispatch(setReference(app.reference))
            store.dispatch(setProductRange(appconfig.ranges[app.productRange?.replace("'", "").toLowerCase()]))
            store.dispatch(setImageUrl(app.imageUrl))

            store.dispatch(setBackdrop(false))
        }

        if(context === 'edit') getCurrentApparatus();

        return ()=>{
            store.dispatch(resetApparatusData())
        }
    // eslint-disable-next-line
    }, [lang]);

    const handleChange = event => {
       if(event.target.name === "enabled")
        setFormData({name: event.target.name,value: event.target.checked});
       else
        setFormData({name: event.target.name,value: event.target.value});
    }    

    const openRow = event => {
        navigate({pathname: "/exercise/"+ event.currentTarget.dataset.id, search: "?apparatus=" + apparatus.id })
    }


    const handleClickOpen = () => {
    setOpenModalDeleteExercise(true); // Set modal to open
};

const handleClose = () => {
    setOpenModalDeleteExercise(false); // Set modal to close
    setExerciseToDelete(null); // Reset the exercise to delete
};



    const deleteExerciseAction = async () => {
        try {
            setLoading(true);
            store.dispatch(setBackdrop(true));
            const success = await deleteExerciseService(exerciseToDelete);
            if (success) {
                const newExercises = rows.filter(s => Number(s.id) !== Number(exerciseToDelete));
                store.dispatch(setRows(newExercises));
            } else {
                alert("Impossible de supprimer l'activité. Un entrainement existe pour cet agrès.");
            }
        } catch (error) {
            console.error('Error deleting exercise:', error);
            alert("Impossible de supprimer l'activité. Un entrainement existe pour cet agrès.");
        } finally {
            handleClose();
            setLoading(false);
            store.dispatch(setBackdrop(false));
        }
    }

    const handleSubmit = async (event) => {

        event.preventDefault();

        if(!handleValidation())
        {
            store.dispatch(setHasError(true));
            store.dispatch(setPreviewImg(apparatus.picture ?? ""));
            store.dispatch(setSelectedFile(null));
            store.dispatch(setIsFilePicked(false));
            return false;
        }

        //Pourquoi ? Parce que sinon ça marche pas.¯\_(ツ)_/¯
        let submitedData = formData;
        submitedData.picture = apparatus.picture;
        submitedData.enabled = Boolean(submitedData.enabled);

        store.dispatch(setBackdrop(true))
        var app = false;

        if(isFilePicked)
        {
            let uploadData = new FormData();
            uploadData.append('file', selectedFile, selectedFile.name);
            let uploadInfo = await uploadFile(uploadData);
            store.dispatch(setPreviewImg(uploadInfo.contentUrl))
            store.dispatch(setIsFilePicked(false))
            submitedData.picture = uploadInfo.contentUrl;
        }

        if(isNotEmpty(apparatus.id) && !isNaN(Number(apparatus.id))){
            app = await patchApparatus(apparatus.id, submitedData, lang)
        }
        else{
            submitedData.inclusive = false
            app = await createApparatus(submitedData, lang);
        }

        if(app.status === 'error')
        {
            store.dispatch(setOpenAlert(true))
            store.dispatch(addMessageAlert({message: messagesconfig.EditApparatus.ERROR_SAVE_APPARATUS, type: "error"}))
        }
        else if(isNotEmpty(app.data.id))
        {
            store.dispatch(setOpenAlert(true))
            store.dispatch(addMessageAlert({message: messagesconfig.EditApparatus.SUCCESS_SAVE_APPARATUS, type: "success"}))
            store.dispatch(setApparatus(app.data))
            navigate({pathname: "/apparatus/"+ app.data.id});
        }

        store.dispatch(setBackdrop(false))
    }

    const handleValidation = () => {
        let fields = formData;
        let errors = {...validationErrors};
        let formIsValid = true;

        //Name
        if (!fields["name"]) {
          formIsValid = false;
          errors["name"] = messagesconfig.EditApparatus.ERROR_FIELD_NAME;
          store.dispatch(addMessageAlert({message: messagesconfig.EditApparatus.ERROR_FIELD_NAME, type: "error"}))
        }

        //Reference
        if (!fields["reference"]) {
          formIsValid = false;
          errors["reference"] = messagesconfig.EditApparatus.ERROR_FIELD_REFERENCE;
          store.dispatch(addMessageAlert({message: messagesconfig.EditApparatus.ERROR_FIELD_REFERENCE, type: "error"}))
        }

        //productRange
        if (!fields["productRange"]) {
          formIsValid = false;
          errors["productRange"] = messagesconfig.EditApparatus.ERROR_FIELD_RANGE;
          store.dispatch(addMessageAlert({message: messagesconfig.EditApparatus.ERROR_FIELD_RANGE, type: "error"}))
        }

        //picture
        if (!selectedFile && !apparatus.picture) {
            formIsValid = false;
            errors["picture"] = messagesconfig.EditApparatus.ERROR_FIELD_PICTURE;
            store.dispatch(addMessageAlert({message: messagesconfig.EditApparatus.ERROR_FIELD_PICTURE, type: "error"}))
        }

        store.dispatch(setValidationErrors(errors))

        return formIsValid;
      }

    if(!params.id ||(params.id && Object.keys(apparatus).length > 0)) {
    	return (
            <>

                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container spacing={2}>
                    <PageTitle title={context === 'edit' ? "Editer un agrès" : "Ajouter un agrès"} />
                    <Grid  item display="flex" justifyContent="right" xs={8} md={6}>
                        <FormControl size="small" sx={{ mt:2, width: '150px' }}>
                            <InputLabel id="lblLang">Langue</InputLabel>
                            <Select labelId="lblLang" id="lang" name="lang" label="Langue" onChange={(e)=>{store.dispatch(setLang(e.target.value));}} value={lang}>
                                <MenuItem value="de">🇩🇪 Allemand</MenuItem>
                                <MenuItem value="en">🇬🇧 Anglais</MenuItem>
                                <MenuItem value="au">🇦🇺 Australien</MenuItem>
                                <MenuItem value="fr">🇫🇷 Français</MenuItem>
                                <MenuItem value="it">🇮🇹 Italien</MenuItem>
                                <MenuItem value="nl">🇳🇱 Néerlandais</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid component="form" item  sx={{ m: 2 }} md={4} onSubmit={handleSubmit} noValidate>
                        <Grid item md={6}>
                            <FormControl sx={{alignItems: 'center', m: 1 }} >
                                <Typography component="legend">Actif</Typography>
                                <Switch checked={enabled} name="enabled" onChange={(e)=>{
                                    handleChange(e)
                                    store.dispatch(setEnabled(!enabled))
                                }} color="success"/>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ m: 1 }} fullWidth variant="standard" focused={true}>
                                <TextField required {...(validationErrors['name'] && { error: true })}  helpertext={validationErrors['name']} id="name" label="Nom" name="name" 
                                onChange={(e)=>{
                                    if(e.target.value !== ''){
                                        store.dispatch(setValidationErrors(removeErrorsFields(['name'], validationErrors)))
                                    }
                                    handleChange(e)
                                    store.dispatch(setName(e.target.value))
                                }} value={name}  />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ m: 1 }} fullWidth focused={true}>
                                <TextField required {...(validationErrors['reference'] && { error: true })} helpertext={validationErrors['reference']} id="reference" label="Référence" name="reference" 
                                onChange={(e)=>{
                                    if(e.target.value !== ''){
                                        store.dispatch(setValidationErrors(removeErrorsFields(['reference'], validationErrors)))
                                    }
                                    handleChange(e)
                                    store.dispatch(setReference(e.target.value))
                                }} value={reference} />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ m: 1 }} focused={true} fullWidth {...(validationErrors['productRange'] && { error: true })}>
                                <InputLabel id="lblProductRange">Gamme *</InputLabel>
                                <Select required {...(validationErrors['productRange'] && { error: true })} helpertext={validationErrors['productRange']} labelId="lblProductRange" id="productRange" name="productRange" label="Gamme" 
                                onChange={(e)=>{
                                    setFormData({name: e.target.name,value: appconfig.rangesStr[e.target.value]});
                                    store.dispatch(setProductRange(e.target.value))
                                }} value={productRange || ""}>
                                    <MenuItem value={1}>Acti'Street</MenuItem>
                                    <MenuItem value={2}>Acti'Ninja</MenuItem>
                                    <MenuItem value={3}>Acti'Fit</MenuItem>
                                </Select>
                                <FormHelperText>{validationErrors['productRange']}</FormHelperText>
                            </FormControl>
                        </Grid>
                        {/* <Grid item>
                            <FormControl sx={{ m: 1 }} fullWidth variant="standard">
                                <TextField required {...(validationErrors['imageUrl'] && { error: true })}  helpertext={validationErrors['imageUrl']} id="imageUrl" label="Lien image" name="imageUrl" 
                                onChange={(e) => {
                                    if(e.target.value !== ''){
                                        store.dispatch(setValidationErrors(removeErrorsFields(['imageUrl'], validationErrors)))
                                    }
                                    handleChange(e)
                                    store.dispatch(setImageUrl(e.target.value))
                                    }
                                } value={imageUrl || ""} focused={true} />
                            </FormControl>
                        </Grid> */}
                        <Grid item>
                            <FormControl sx={{ m: 1 }} fullWidth variant="standard" {...(validationErrors['picture'] && { error: true })}>
                                <Typography {...(validationErrors['picture'] && { sx: { color: 'error.main'  }})} component="legend">Illustration *</Typography>
                                <label htmlFor="picture">
                                    <Input required sx={{ display: 'none' }} name="picture" accept="image/*" id="picture" onChange={(event) => {previewImage(event)}} type="file" />
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                                <FormHelperText>{validationErrors['picture']}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ m: 1 }} fullWidth variant="standard">
                                <Button type="submit" fullWidth variant="contained">Enregistrer</Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} md={4} sx={{ m: 2 }}>
                        <Box sx={{ backgroundImage:  `url(${previewImg})`, backgroundSize: "contain", backgroundRepeat: 'no-repeat', minHeight: '150px',height:'100%', width: '100%'}} />
                    </Grid>
                </Grid>
                {
                    (() => {

                    if(context === 'edit') {
                    return(    <>
                    <h1>Les activités de l'agrès</h1>  
 
                    <Box>
                        <Box display="flex" justifyContent="right" mb={2}>
                            <Button onClick={() => {navigate("/exercise?apparatus=" + apparatus.id);}} variant="contained" startIcon={<AddCircleIcon />}>Créer une activité</Button>
                        </Box>
                        <Box sx={{ height: 500, width: '100%', '& .disabledRow': {bgcolor: '#e1e1e1'} }}>
                          <DataGrid localeText={{...frFR.components.MuiDataGrid.defaultProps.localeText}} sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' }, }} rows={rows} columns={actcolumns}  autoHeight disableSelectionOnClick componentsProps={{ row: {onClick: openRow } }} getRowClassName={(params) => params.row.enabled ? '' : 'disabledRow'} />
                        </Box> 
                        <div>
                            <ModalDelete
                                openModal={openModalDeleteExercise}
                                handleClose={handleClose}
                                action={() => {
                                    deleteExerciseAction()
                                }}
                                message="Êtes-vous sûr de vouloir supprimer cette activité ? Cette action est irréversible."
                            />
                        </div>
                      
                    </Box>
                    </>);
                    }
                    })() 
                }

            </>
        );
    }
    else {
        return (
            <>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
            );
    }
}