import { deleteExercise } from "../api/ExerciceAPI"
import { addMessageAlert, setOpenAlert } from "../redux/App/AppSlice"
import { store } from "../redux/Store"
import { isNotEmpty } from "./AppServices"
import messagesconfig from "../config/messagesconfig.json"

export const deleteExerciseService = async (id_exercise) => {
    if(isNotEmpty(id_exercise) && !isNaN(id_exercise) ){
        let result = await deleteExercise(id_exercise)



        let message = ''
        let type = false
        if(result.status === 'error' || result.data.error === true){
            message = messagesconfig.SiteServices.ERROR_DELETE_EXERCISE
            type = "error"
            
        }else{
            message = messagesconfig.SiteServices.SUCCESS_DELETE_EXERCISE
            type = "success"
        }
        store.dispatch(setOpenAlert(true))
        store.dispatch(addMessageAlert({message: message, type: type}))

        if (type == "success"){
            return true;
        }
        else{
            return false;
        }


    }
    store.dispatch(addMessageAlert({message: messagesconfig.ExerciseServices.ERROR_DELETE_EXERCISE, type: "error"}))
}