import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useParams } from "react-router-dom";
import appconfig from "../../config/appconfig.json";

export default function ResetDone(props) {

	const params = useParams();
	const translations = appconfig.translations[2][params.lang] ?? appconfig.translations[2]["fr"];

    return ( 
        <Container component="main">
            <Grid sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src="/logo-actifun.png" height="80" alt="actifun"/>
                <Grid item xs={12}>
	                <Typography variant="h5" sx={{mb:5, mt: 5, width: '100%'}}>
	                	{translations['reset_title']}
	                </Typography>
	                
	    			<Typography variant="body1" sx={{  mb:2, mt: 2, width: '100%' }}>
                    {translations['reset_message'].split("\n").map((i, key) => {
                        return <div key={key}>{i}</div>;
                    })}
	                </Typography>
                    
                    <Button type="button" href="/open-app" target="_blank" variant="contained" sx={{ mt: 3, mb: 2 }}>{translations['access_app']}</Button>

                </Grid>
            </Grid>
        </Container>
        );
    }