import React, { useState, useEffect } from 'react'
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import { Backdrop, CircularProgress } from '@mui/material'
import { getSites } from "../../api/SiteAPI"
import { useNavigate } from "react-router-dom"
import PageTitle from '../../components/PageTitle'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import { useSelector } from 'react-redux'
import { setLoading, setOpenModalDeleteSite, setIdSiteToDelete } from '../../redux/Site/SiteSlice'
import { store } from '../../redux/Store'
import { setBackdrop } from '../../redux/App/AppSlice'
import ModalDelete from '../../components/modal/ModalDelete'
import InfoIcon from '@mui/icons-material/Info'
import { deleteSiteService } from '../../services/SiteServices'
import { isNotEmpty } from '../../services/AppServices'
import TooltipGrid from '../../components/shared/TooltipGrid'

export default function Sites(props) {

    let navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const loading = useSelector((state) => state.site.loading)
    const openModalDeleteSite = useSelector((state) => state.site.openModalDeleteSite)
    const idSiteToDelete = useSelector((state) => state.site.idSiteToDelete)
    const backdrop = useSelector((state) => state.app.backdrop)

    const handleClickOpen = () => {
        store.dispatch(setOpenModalDeleteSite(true))
    };

    const handleClose = () => {
        store.dispatch(setOpenModalDeleteSite(false))
        store.dispatch(setIdSiteToDelete(null))
    };

    const deleteSiteAction = async () => {
        store.dispatch(setBackdrop(true))
        deleteSiteService(idSiteToDelete)
        let newSites = [...rows].filter(s => Number(s.id) !== Number(idSiteToDelete))
        setRows(newSites)
        handleClose()
        store.dispatch(setLoading(false))
        store.dispatch(setBackdrop(false))
    }

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'ID', renderHeader: (params) => (
                <strong>{"ID"}</strong>
            )
        },
        {
            field: 'name', headerName: 'Nom', flex: 1, renderHeader: (params) => (
                <TooltipGrid value={"Nom"} jsx={<strong>{"Nom"}</strong>} />
            ),
            renderCell: (params) => {
                let value = params.row.name
                if (!isNotEmpty(params.row.name)) {
                    value = params.row.address
                }
                return <TooltipGrid value={value} jsx={<div>{value}</div>} />

            }
        },
        {
            field: 'address', headerName: 'Adresse', flex: 1, renderHeader: (params) => (
                <TooltipGrid value={"Adresse"} jsx={<strong>{"Adresse"}</strong>} />
            ),
            renderCell: (params) => {
                return <TooltipGrid value={params.row.address} jsx={<div>{params.row.address}</div>} />
            }
        },
        {
            field: 'zipcode', headerName: 'Code Postal', flex: 1,
            renderHeader: () => (
                <TooltipGrid value={"Code Postal"} jsx={<strong>{"Code Postal"}</strong>} />
            )
        },
        {
            field: 'city', headerName: 'Ville', flex: 1,
            renderHeader: () => (
                <TooltipGrid value={"Ville"} jsx={<strong>{"Ville"}</strong>} />
            ),
            renderCell: (params) => (
                <TooltipGrid value={params.row.city} jsx={<div>{params.row.city}</div>} />
            )
        },
        {
            field: 'country', headerName: 'Pays', flex: 1,
            renderHeader: () => (
                <TooltipGrid value={"Pays"} jsx={<strong>{"Pays"}</strong>} />
            )
        },
        {
            field: 'delete', headerName: 'Supprimer', flex: 1,
            renderHeader: () => (
                <TooltipGrid value={"Supprimer"} jsx={<strong>{"Supprimer"}</strong>} />
            ),
            renderCell: (params) => {
                return <IconButton aria-label="delete" onClick={(e) => {
                    e.stopPropagation()
                    store.dispatch(setIdSiteToDelete(params.row.id))
                    handleClickOpen()
                }}>
                    <ClearIcon sx={{ color: 'red' }} />
                </IconButton>
            }
        }
    ];

    useEffect(() => {
        store.dispatch(setLoading(true))
        async function createData() {
            const rows = await getSites()
            setRows(rows);
            store.dispatch(setLoading(false))
        }
        createData();

        return () => {
            store.dispatch(setIdSiteToDelete(null))
        }
    }, []);

    const openRow = event => {
        navigate("/site/" + event.currentTarget.dataset.id)
    }

    return (
        <Box>
            <PageTitle title="Liste des sites" />
            <Box sx={{ width: '100%', '& .disabledRow': { bgcolor: '#fff' } }}>
                <DataGrid localeText={{ ...frFR.components.MuiDataGrid.defaultProps.localeText }} sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' }, }} rows={rows} columns={columns} loading={loading} autoHeight componentsProps={{ row: { onClick: openRow }, }} disableSelectionOnClick getRowClassName={(params) => params.row.enabled ? '' : 'disabledRow'} />
            </Box>
            <Box style={{ marginTop: '-38px', fontSize: '15px', display: 'flex', marginLeft: '20px' }}>
                <InfoIcon sx={{ color: 'orange', mr: 1 }} />
                <span>Le rayon d'un site est de 150 mètres</span>
            </Box>
            <div>
                <ModalDelete
                    openModal={openModalDeleteSite}
                    handleClose={handleClose}
                    action={() => {
                        deleteSiteAction()
                    }}
                    message="Etes vous sûr(e) de vouloir supprimer le site ?"
                />
            </div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
