import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export default function QR(props) {

	navigate();
	function navigate() {
	    if((/Android/i.test(navigator.userAgent))){
	        window.location.href = 'https://play.google.com/store/apps/details?id=com.proludic.actifun';
	    }
	    else if(/iPad|iPhone|iPod/i.test(navigator.userAgent)){
	        window.location.href = 'https://apps.apple.com/fr/app/actifun-by-proludic/id1627830653';
	    }
	    else{
	        window.location.href = 'https://www.proludic.com/';
	    }
	}

	return (
		<Container component="main" maxWidth="xs">
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src="/logo512.png" width="256" alt="actifun"/>
            </Box>
        </Container>
	);
}
