import axios from "axios";
import authHeader from "../services/authHeader";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export function getDashboard(lang) {
    try { 
		return axios.get(apiUrl + 'dashboard', authHeader()).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}