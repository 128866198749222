import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    dash: false
}

export const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDash: (state, action) => {
            state.dash = action.payload
        },
    }
})

export const {setDash} = DashboardSlice.actions
export default DashboardSlice.reducer
