import authHeader from "./authHeader";
const apiUrl = process.env.REACT_APP_API_BASE_URL

export async function upload(uploadData)
{
    let headers = authHeader();
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();

        xhr.addEventListener("readystatechange", function() {
          if(this.readyState === 4) {
            if (this.status >= 200 && this.status < 300) {
                resolve(JSON.parse(this.responseText));
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
          }
        });
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.open("POST", apiUrl + 'media_objects');
        xhr.setRequestHeader("Authorization", headers.headers.Authorization);

        xhr.send(uploadData);
    });
}