import axios from "axios";
import authHeader from "./authHeader";
const apiUrl = process.env.REACT_APP_API_BASE_URL

export function getUsers(lang) {
    try { 
		return axios.get(apiUrl + 'users', authHeader(lang)).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}

export function getUsersByToken(token) {
    try { 
        return axios.get(apiUrl + 'users?activation_token=' + token, authHeader()).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}

export function getUser(id, lang) {
    try { 
        return axios.get(apiUrl + 'users/'+id, authHeader(lang)).then(function (response){
            return response.data
        });
    } catch (error) {
        return null;
    }
}

export function patchUser(id, formData, lang) {
    try { 
        return axios.patch(apiUrl + 'users/'+id, formData, authHeader(lang)).then(function (response){return response.data}).catch(function (error) {return error});
    } catch (error) {
        return null;
    }
}

export function createUser(formData, lang) {
    try { 
        return axios.post(apiUrl + 'users', formData, authHeader(lang)).then(function (response){return response.data}).catch(function (error) {return error});
    } catch (error) {
        return null;
    }
}

export function validateUser(formData, lang) {
    try {  
        return axios.post(apiUrl + 'activate-account', formData, authHeader(lang)).then(function (response){return response.data}).catch(function (error) {return error});
    } catch (error) {
        return null;
    }
}