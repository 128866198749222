import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, frFR, GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getApparatuses } from '../../api/ApparatusAPI';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PageTitle from '../../components/PageTitle';
import { setRowsList} from '../../redux/Apparatus/ApparatusSlice';
import { setBackdrop } from '../../redux/App/AppSlice'
import { store } from '../../redux/Store';
import TooltipGrid from '../../components/shared/TooltipGrid';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {deleteApparatusOnlyService} from '../../services/ApparatusServices';
import ModalDelete from '../../components/modal/ModalDelete'

import { setLoading, setOpenModalDeleteApparatus, setIdApparatusToDelete } from '../../redux/Apparatus/ApparatusSlice'

export default function Apparatus(props) {
    let navigate = useNavigate();

    const rowsList = useSelector((state) => state.apparatus.rowsList);
    const loading = useSelector((state) => state.apparatus.loading)
    const openModalDeleteApparatus = useSelector((state) => state.apparatus.openModalDeleteApparatus)
    const idApparatusToDelete = useSelector((state) => state.apparatus.idApparatusToDelete)
    const backdrop = useSelector((state) => state.app.backdrop)

    const [searchRef, setSearchRef] = useState('');
    const [searchName, setSearchName] = useState('');
    const [searchRange, setsearchRange] = useState('');


    const handleClickOpen = () => {
        store.dispatch(setOpenModalDeleteApparatus(true))
    };

    const handleClose = () => {
        store.dispatch(setOpenModalDeleteApparatus(false))
        store.dispatch(setIdApparatusToDelete(null))
    };

    const deleteApparatusAction = async () => {
        try {
            store.dispatch(setBackdrop(true));
            const success = await deleteApparatusOnlyService(idApparatusToDelete);
            if (success) {
                const newApparatuses = rowsList.filter(s => Number(s.id) !== Number(idApparatusToDelete));
                store.dispatch(setRowsList(newApparatuses));
            } else {
                alert("Impossible de supprimer l'agrès. Une activité existe encore pour cet agrès.");
            }
        } catch (error) {
            console.error('Error deleting apparatus:', error);
            alert("Impossible de supprimer l'agrès. Une activité existe encore pour cet agrès.");
        } finally {
            handleClose();
            store.dispatch(setLoading(false));
            store.dispatch(setBackdrop(false));
        }
    }



    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', renderHeader: (params) => <strong>{'ID'}</strong> },
        {
            field: 'picture',
            flex: 1,
            headerName: 'Image',
            renderHeader: (params) => <TooltipGrid value={'Image'} jsx={<strong>{'Image'}</strong>} />,
            renderCell: (params) => <img alt="Illustration" width="18%" src={params.value} />,
        },
        {
            field: 'productRange',
            headerName: 'Gamme',
            flex: 1,
            renderHeader: (params) => <TooltipGrid value={'Gamme'} jsx={<strong>{'Gamme'}</strong>} />,
        },
        {
            field: 'reference',
            headerName: 'Référence',
            flex: 1,
            renderHeader: (params) => <TooltipGrid value={'Référence'} jsx={<strong>{'Référence'}</strong>} />,
        },
        {
            field: 'name',
            headerName: 'Nom',
            flex: 1,
            renderHeader: (params) => <TooltipGrid value={'Nom'} jsx={<strong>{'Nom'}</strong>} />,
        },
        {
            field: 'enabled',
            flex: 1,
            headerName: 'Actif',
            renderHeader: (params) => <TooltipGrid value={'Actif'} jsx={<strong>{'Actif'}</strong>} />,
            renderCell: (params) => {
                return params.value ? <CheckCircleOutlinedIcon color="success" /> : <CancelOutlinedIcon color="error" />;
            },
        },
        {
            field: 'delete', headerName: 'Supprimer', flex: 1,
            renderHeader: () => (
                <TooltipGrid value={"Supprimer"} jsx={<strong>{"Supprimer"}</strong>} />
            ),
            renderCell: (params) => {
                return <IconButton aria-label="delete" onClick={(e) => {
                    e.stopPropagation()
                    store.dispatch(setIdApparatusToDelete(params.row.id))
                    handleClickOpen()
                }}>
                    <ClearIcon sx={{ color: 'red' }} />
                </IconButton>
            }
        },
    ];

   useEffect(() => {
    
        async function createData() {
            store.dispatch(setLoading(true));
            const rows = await getApparatuses();
            store.dispatch(setRowsList(rows));
            store.dispatch(setLoading(false));
        }
        createData();
    }, []);




    // Filtered rows based on search
    const filteredRows = rowsList.filter((row) => {
        return (
            (searchRef === '' || row.reference.toLowerCase().includes(searchRef.toLowerCase())) &&
            (searchName === '' || row.name.toLowerCase().includes(searchName.toLowerCase())) &&
            ((searchRange === '' || searchRange === 'Toutes') || row.productRange === searchRange)
        );
    });

    const openRow = (event) => {
        navigate('/apparatus/' + event.currentTarget.dataset.id);
    };

    return (
        <Box>
            <PageTitle title="Liste des agrès" />
            <Box display="flex" justifyContent="right" mb={2}>
                <Button onClick={() => navigate('/apparatus')} variant="contained" startIcon={<AddCircleIcon />}>
                    Créer un agrès
                </Button>
            </Box>

            <Box component="fieldset" borderColor="primary.main" borderRadius={2} p={2} mb={2} border={1} style={{ position: 'relative' }}>
                <legend style={{ marginLeft: 10, padding: '0 10px', fontSize: '1rem', color: '#000000de' }}>Recherche</legend>
                <Box display="flex" justifyContent="space-between">
                    <TextField
                        label="Référence"
                        variant="outlined"
                        size="small"
                        value={searchRef}
                        onChange={(e) => setSearchRef(e.target.value)}
                        sx={{ marginRight: 2, width: '30%' }}
                    />
                    <TextField
                        label="Nom"
                        variant="outlined"
                        size="small"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        sx={{ marginRight: 2, width: '30%' }}
                    />
                    <Select
                        label="Gamme"
                        variant="outlined"
                        value={searchRange}
                        onChange={(e) => setsearchRange(e.target.value)}
                        displayEmpty
                        size="small"
                        sx={{ width: '30%' }}
                    >
                        <MenuItem value="">
                            <em>Gamme</em>
                        </MenuItem>
                        <MenuItem value="Acti'Street">Acti'Street</MenuItem>
                        <MenuItem value="Acti'Fit">Acti'Fit</MenuItem>
                    </Select>
                </Box>
            </Box>

            <Box sx={{ height: 500, width: '100%', '& .disabledRow': { bgcolor: '#fff' } }}>
                <DataGrid
                    localeText={{ ...frFR.components.MuiDataGrid.defaultProps.localeText }}
                    sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' } }}
                    rows={filteredRows}
                    columns={columns}
                    loading={loading || rowsList.length === 0}
                    autoHeight
                    componentsProps={{ row: { onClick: openRow } }}
                    disableSelectionOnClick
                    getRowClassName={(params) => (params.row.enabled ? '' : 'disabledRow')}
                />
            </Box>
            <div>
                <ModalDelete
                    openModal={openModalDeleteApparatus}
                    handleClose={handleClose}
                    action={() => {
                        deleteApparatusAction()
                    }}
                    message="Etes vous sûr(e) de vouloir supprimer l'agrès ?"
                />
            </div>
        </Box>
    );
}
