import axios from "axios";
import authHeader from "../services/authHeader";
const apiUrl = process.env.REACT_APP_API_BASE_URL

export function getSites() {
    try { 
		return axios.get(apiUrl + 'sites?itemsPerPage=1000', authHeader()).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}

export function getSite(id, lang) {
    
    try { 
        return axios.get(apiUrl + 'sites/'+id, authHeader(lang)).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}

export function deleteSite(id) {

    try { 
        return axios.delete(apiUrl + 'sites/'+id, authHeader()).then(function (response){return {status: 'success', data: response.data}}).catch((error) => ({status: 'error', data: error}));
    } catch (error) {
        return {status: 'error', data: error};
    }
}

export function patchSite(id, name, lang) {

    try {
        return axios.patch(apiUrl + 'sites/'+id, { name: name}, authHeader(lang)).then(response => ({status: 'success', data: response.data})).catch((error) => ({status: 'error', data: error}));
    } catch (error) {
        return {status: 'error', data: error};
    }
}