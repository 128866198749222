import axios from "axios";
import authHeader from "../services/authHeader";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export function getApparatuses() {
    try { 
		return axios.get(apiUrl + 'apparatuses?itemsPerPage=1000', authHeader()).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}

export function getApparatus(id, lang) {
    
    try { 
        return axios.get(apiUrl + 'apparatuses/'+id, authHeader(lang)).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}


export function getApparatusByReference(reference) {
    try { 
        return axios.get(apiUrl + 'apparatuses?reference=' + reference, authHeader()).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}

export function getApparatusExercises(id, lang) {
    try { 
        return axios.get(apiUrl + 'apparatuses/'+id+'/exercises', authHeader(lang)).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}

export function patchApparatus(id, formData, lang) {
    try { 
        return axios.patch(apiUrl + 'apparatuses/'+id, formData, authHeader(lang)).then(function (response){return {status: 'success', data: response.data}}).catch((error) => ({status: 'error', data: error}));
    } catch (error) {
        return {status: 'error', data: error};
    }
}

export function createApparatus(formData, lang) {
    try { 
        return axios.post(apiUrl + 'apparatuses', formData, authHeader(lang)).then(function (response){return {status: 'success', data: response.data}}).catch(function (error) {return error});
    } catch (error) {
        return null;
    }
}

export function getApparatusesBySite(id_site) {
    try { 
		return axios.get(apiUrl + 'apparatuses/sites/'+id_site+'?itemsPerPage=1000', authHeader()).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}

export function deleteApparatus(id_apparatus, id_site) {

    try { 
        return axios.delete(apiUrl + 'apparatus/'+id_apparatus+'/site/'+id_site+'/delete', authHeader()).then(function (response){return {status: 'success', data: response.data}}).catch((error) => ({status: 'error', data: error}));
    } catch (error) {
        return {status: 'error', data: error};
    }
}

export function deleteOnlyApparatus(id_apparatus) {

    try { 
        return axios.delete(apiUrl + 'apparatuses/'+id_apparatus, authHeader()).then(function (response){return {status: 'success', data: response.data}}).catch((error) => ({status: 'error', data: error}));
    } catch (error) {
        return {status: 'error', data: error};
    }
}