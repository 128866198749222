import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    backdrop: false,
    openAlert: false,
    messagesAlert: []
}

export const AppSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setBackdrop: (state, action) => {
            state.backdrop = action.payload
        },
        setOpenAlert: (state, action) => {
            state.openAlert = action.payload
        },
        addMessageAlert: (state, action) => {
            let ma = [...state.messagesAlert]
            ma.push({
                message: action.payload.message,
                type: action.payload.type
            })
            state.messagesAlert = ma
        },
        removeMessagesAlert: (state) => {
            state.messagesAlert = []
        }
    }
})

export const {setBackdrop, setOpenAlert, addMessageAlert, removeMessagesAlert} = AppSlice.actions
export default AppSlice.reducer
