import { configureStore } from '@reduxjs/toolkit'
import LoginReducer from './Login/LoginSlice'
import DashboardReducer from './Dashboard/DashboardSlice'
import SiteReducer from './Site/SiteSlice'
import ApparatusReducer from './Apparatus/ApparatusSlice'
import ExerciceReducer from './Exercice/ExerciceSlice'
import AppReducer from './App/AppSlice'

export const store = configureStore({
    reducer: {
        login: LoginReducer,
        dashboard: DashboardReducer,
        site: SiteReducer,
        apparatus: ApparatusReducer,
        exercice: ExerciceReducer,
        app: AppReducer
    }
})