import React from "react"
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import Login from './pages/Login/Login'
import Apparatus from './pages/Apparatus/Apparatus'
import EditApparatus from './pages/Apparatus/EditApparatus'
import EditExercise from './pages/Exercice/EditExercise'
import Dashboard from './pages/Dashboard/Dashboard'
import ResetPassword from './pages/Login/ResetPassword'
import ResetDone from './pages/Login/ResetDone'
import Welcome from './pages/Welcome'
import Qr from './pages/Qr'
import Sites from './pages/Site/Sites'
import EditSite from './pages/Site/EditSite'
import Layout from './components/layout'
import { getCurrentUser } from "./services/authService"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ConfirmDialogProvider } from 'react-mui-confirm'
import AddApparatus from "./pages/Apparatus/AddApparatus"
import AddExercise from "./pages/Exercice/AddExercise"

const theme = createTheme({
  palette: {
        type: 'light',
        primary: {
          main: '#575756',
        },
        secondary: {
          main: '#f89522', 
        },
      },
});

const App =() => {

    const reload = () => window.location.reload();

    function RequireAuth({ children }) {

        let location = useLocation();
        let user = getCurrentUser()
        if(user)
            return children;
        else
            return  <Navigate to="/login" state={{ from: location }} />;
    }

    return (
      <>
        <ThemeProvider theme={theme}>
        <ConfirmDialogProvider>
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Login />} />
            <Route path="/qr/:reference" element={<Qr />} />
            <Route path="/open-app" element={<Qr />} />
            <Route path="/openapp" element={<Qr />} />
            <Route element={<Layout />}>
                <Route path="/sites" element={<RequireAuth><Sites /></RequireAuth>} />
                <Route path="/site/:id" element={<RequireAuth><EditSite /></RequireAuth>} />
                <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
                <Route path="/apparatuses" element={<RequireAuth><Apparatus /></RequireAuth>} />
                <Route path="/apparatus" element={<RequireAuth><AddApparatus /></RequireAuth>} />
                <Route path="/apparatus/:id" element={<RequireAuth><EditApparatus /></RequireAuth>} />
                <Route path="/exercise" element={<RequireAuth><AddExercise /></RequireAuth>} />
                <Route path="/exercise/:id" element={<RequireAuth><EditExercise /></RequireAuth>} />
            </Route>
            <Route path="/reset-password/:lang/:token" element={<ResetPassword />} />
            <Route path="/reset-confirmed/:lang" element={<ResetDone />} />
            <Route path="/confirm-account/:lang/:token" element={<Welcome />} />
            <Route path=".well-known/assetlinks.json" onEnter={reload} />
            <Route path=".well-known/apple-app-site-association" onEnter={reload} />
            <Route path="*" element={<div><p>404</p></div>} />
        </Routes>
        </ConfirmDialogProvider>
        </ThemeProvider>
      </>
    );
  
}
export default App;
