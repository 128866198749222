import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FormControl, TextField, FormHelperText, InputLabel, Input, IconButton, Backdrop, CircularProgress, Grid, Button, Box, Select, MenuItem, Rating, Typography, Switch} from '@mui/material'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import { upload as uploadFile } from "../../services/uploadService"
import { getExercise, patchExercise, createExercise } from "../../api/ExerciceAPI"
import ApparatusCard from "../Apparatus/ApparatusCard"
import PageTitle from '../PageTitle'
import {useSearchParams, useNavigate} from "react-router-dom"
import {setExercise, setVideoUrl, setVideoEmbedUrl, setIsFilePicked, setMusclesImg,setValidationErrors, setRep, setPointsByRep, setChrono, setPointsByTime, setMaxTime, setEnabled, setName, setDescription, setLevel, setInclusive, setPrefFamily, setPrefPhysicalCondition, setPrefShape, setPrefMuscleMass, setPrefFun, setPrefGroup, setPrefWheelchair, setPrefChallenge, setPrefThrill, addPreference, removePreference, setPreferences} from '../../redux/Exercice/ExerciceSlice'
import { store } from '../../redux/Store'
import { addMessageAlert, setBackdrop } from '../../redux/App/AppSlice'
import Divider from '@mui/material/Divider';
import { isNotEmpty, isUrl, removeErrorsFields, validateVimeoURL } from '../../services/AppServices';
import {ReactComponent as IconFamily} from '../../_images/icons/preferences/family.svg'
import {ReactComponent as IconFun} from '../../_images/icons/preferences/fun.svg'
import {ReactComponent as IconSneaker} from '../../_images/icons/preferences/sneaker.svg'
import {ReactComponent as IconHeart} from '../../_images/icons/preferences/heart.svg'
import {ReactComponent as IconLightning } from '../../_images/icons/preferences/lightning.svg'
import {ReactComponent as IconWheelchair} from '../../_images/icons/preferences/wheelchair.svg'
import {ReactComponent as IconPeoples} from '../../_images/icons/preferences/peoples.svg'
import {ReactComponent as IconMuscle} from '../../_images/icons/preferences/muscle.svg'
import {ReactComponent as IconAward} from '../../_images/icons/preferences/award.svg'
import messagesconfig from "../../config/messagesconfig.json"

let messages = messagesconfig.EditExercise

const preferenceCssForm = {alignItems: 'center', display: 'flex', flexDirection: 'row'}
const preferenceCssTypo = {position: 'absolute', left: '90px', width: '330px'}

export default function EditExerciseForm({context}) {
    let navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [lang, setLang] = useState('fr')
    const level = useSelector((state)=>state.exercice.level)
    const description = useSelector((state)=>state.exercice.description)
    const name = useSelector((state)=>state.exercice.name)
    const enabled = useSelector((state)=>state.exercice.enabled)
    const inclusive = useSelector((state)=>state.exercice.inclusive)
    const exercise = useSelector((state)=>state.exercice.exercise)
    const backdrop = useSelector((state)=>state.app.backdrop)
    const videoUrl = useSelector((state)=>state.exercice.videoUrl)
    const videoEmbedUrl = useSelector((state)=>state.exercice.videoEmbedUrl)
    const [selectedFile, setSelectedFile] = useState()
    const musclesImg = useSelector((state)=>state.exercice.musclesImg)
    const validationErrors = useSelector((state)=>state.exercice.validationErrors)
    const rep = useSelector((state)=>state.exercice.rep)
    const pointsByRep = useSelector((state)=>state.exercice.pointsByRep)
    const chrono = useSelector((state)=>state.exercice.chrono)
    const pointsByTime = useSelector((state)=>state.exercice.pointsByTime)
    const maxTime = useSelector((state)=>state.exercice.maxTime)
    const prefFamily = useSelector((state)=>state.exercice.prefFamily)
    const prefPhysicalCondition = useSelector((state)=>state.exercice.prefPhysicalCondition)
    const prefShape = useSelector((state)=>state.exercice.prefShape)
    const prefMuscleMass = useSelector((state)=>state.exercice.prefMuscleMass)
    const prefFun = useSelector((state)=>state.exercice.prefFun)
    const prefGroup = useSelector((state)=>state.exercice.prefGroup)
    const prefWheelchair = useSelector((state)=>state.exercice.prefWheelchair)
    const prefChallenge = useSelector((state)=>state.exercice.prefChallenge)
    const prefThrill = useSelector((state)=>state.exercice.prefThrill)
    const preferences = useSelector((state)=>state.exercice.preferences)

    useEffect(()=>{
        store.dispatch(setPrefFamily(checkPreferences(1)))
        store.dispatch(setPrefPhysicalCondition(checkPreferences(2)))
        store.dispatch(setPrefShape(checkPreferences(3)))
        store.dispatch(setPrefMuscleMass(checkPreferences(4)))
        store.dispatch(setPrefFun(checkPreferences(5)))
        store.dispatch(setPrefGroup(checkPreferences(6)))
        store.dispatch(setPrefWheelchair(checkPreferences(7)))
        store.dispatch(setPrefChallenge(checkPreferences(8)))
        store.dispatch(setPrefThrill(checkPreferences(9)))
    // eslint-disable-next-line
    }, [preferences])

    useEffect(()=>{
        changeEmbedVideo(videoUrl)
    // eslint-disable-next-line
    }, [videoUrl])

    const getCurrentExercise = useCallback (async () => {

        store.dispatch(setBackdrop(true))
        const exe = await getExercise(params.id, lang);

        store.dispatch(setName(exe.name))
        store.dispatch(setEnabled(Boolean(exe.enabled)))
        store.dispatch(setLevel(Number(exe.level)))
        store.dispatch(setInclusive(Boolean(exe.inclusive)))
        store.dispatch(setVideoUrl(exe.videoUrl))
        store.dispatch(setDescription(exe.description))
        store.dispatch(setRep(exe.rep))
        store.dispatch(setChrono(exe.chrono))
        store.dispatch(setPointsByRep(exe.pointsByRep))
        store.dispatch(setPointsByTime(exe.pointsByTime))
        store.dispatch(setMaxTime(exe.maxTime))
        store.dispatch(setExercise(exe))
        store.dispatch(setMusclesImg(exe.picture))
        store.dispatch(setPreferences(exe.preferences))

        if(isUrl(exe.videoUrl)){
            let url = new URL(exe.videoUrl)
            store.dispatch(setVideoEmbedUrl("https://player.vimeo.com/video/" + url.pathname.substring(1)))
        }
        store.dispatch(setBackdrop(false))
    })

    useEffect(() => {
        if(params.id){
            getCurrentExercise()
        }

        // eslint-disable-next-line
    }, [lang, params.id]);

    const switchLang = event => {
        setLang(event.target.value)
    }

    let previewImage = async (event) => {
        let uploadedFile = event.target.files[0];
        if(uploadedFile && uploadedFile['type'].split('/')[0] === 'image')
        {
            let uploadData = new FormData();
            uploadData.append('file', uploadedFile, uploadedFile.name);
            let uploadInfo = await uploadFile(uploadData);
            store.dispatch(setMusclesImg(uploadInfo.contentUrl))
            setSelectedFile(uploadedFile)
            store.dispatch(setIsFilePicked(true))
        }
        else
        {
            let error = validationErrors;
            error["picture"] = "Mauvais format de fichier.";
            store.dispatch(setValidationErrors(error))
            store.dispatch(setMusclesImg(exercise.picture ?? ""))
            setSelectedFile(null)
            store.dispatch(setIsFilePicked(false))
        }
    }

    const changeEmbedVideo = value => {

        if(value !== '' && isUrl(value) && validateVimeoURL(value))
        {
            let url = new URL(value);
            store.dispatch(setVideoEmbedUrl("https://player.vimeo.com/video/" + url.pathname.substring(1)))
        }
        else{
            store.dispatch(setVideoEmbedUrl(""))
        }
    }

    const handleSubmit = async (e) => {
        
        e.preventDefault();
        if(!handleValidation()) return false;

        const dataToSend = {
            name: name,
            enabled: enabled,
            level: level,
            videoUrl: videoUrl,
            picture: musclesImg,
            description: description,
            rep: rep,
            chrono: chrono,
            pointsByRep: pointsByRep,
            pointsByTime: pointsByTime,
            maxTime: maxTime,
            inclusive: inclusive
        }

        let preferencesIri = []
        for(let p of preferences){
            preferencesIri.push("/api/preferences/"+p.id)
        }
        dataToSend.preferences = preferencesIri

        store.dispatch(setBackdrop(true))

        let exe = false;

        if(exercise.id)
            exe = await patchExercise(exercise.id, dataToSend, lang);
        else
        {
            dataToSend.apparatus = '/api/apparatuses/' + searchParams.get('apparatus');
            exe = await createExercise(dataToSend, lang);
        }

        if(exe.message)
        {
            store.dispatch(setMusclesImg(exercise.picture ?? ""))
            store.dispatch(addMessageAlert({message: messages.ERROR_SAVE_EXERCISE, type: "error"}))
        }
        else
        {
            store.dispatch(addMessageAlert({message: messages.SUCCESS_SAVE_EXERCISE, type: "success"}))
            navigate({pathname: "/exercise/"+ exe.id, search: "?apparatus="+ searchParams.get('apparatus')})
            store.dispatch(setMusclesImg(exe.picture ?? ""))
        }

        store.dispatch(setBackdrop(false))
    }

    const handleValidation = () => {

        store.dispatch(setValidationErrors([]))

        let errors = [];
        let formIsValid = true;

        if (!isNotEmpty(name)) {
          formIsValid = false;
          errors["name"] = true;
          store.dispatch(addMessageAlert({message: messages.ERROR_NAME, type: "error"}))
        }

        if (!isNotEmpty(level)) {
          formIsValid = false;
          errors["level"] = true;
          store.dispatch(addMessageAlert({message: messages.ERROR_LEVEL, type: "error"}))
        }

        if (!isNotEmpty(videoUrl)) {
          formIsValid = false;
          errors["videoUrl"] = true;
          store.dispatch(addMessageAlert({message: messages.ERROR_VIDEO_URL1, type: "error"}))
        }
        else if(!validateVimeoURL(videoUrl))
        {
            formIsValid = false;
            errors["videoUrl"] = true;
            store.dispatch(addMessageAlert({message: messages.ERROR_VIDEO_URL2, type: "error"}))
        }

        if (!isNotEmpty(description)) {
          formIsValid = false;
          errors["description"] = true;
          store.dispatch(addMessageAlert({message: messages.ERROR_DESCRIPTION, type: "error"}))
        }

        if (!isNotEmpty(selectedFile) && !isNotEmpty(exercise.picture) && !isNotEmpty(musclesImg)) {
            formIsValid = false;
            errors["picture"] = true;
            store.dispatch(addMessageAlert({message: messages.ERROR_PICTURE, type: "error"}))
        }

        if(!rep && !chrono){
            formIsValid = false;
            errors["pointsByRep"] = true
            errors["pointsByTime"] = true
            store.dispatch(addMessageAlert({message: messages.ERROR_REP_AND_CHRONO_EMPTY, type: "error"}))
        }

        if(!isNotEmpty(pointsByRep) && rep){
            formIsValid = false;
            errors["pointsByRep"] = true
            store.dispatch(addMessageAlert({message: messages.ERROR_POINTSBYREP, type: "error"}))
        }
        
        if(!isNotEmpty(pointsByTime) && chrono){
            formIsValid = false;
            errors["pointsByTime"] = true
            store.dispatch(addMessageAlert({message: messages.ERROR_POINTSBYTIME, type: "error"}))
        }
        
        store.dispatch(setValidationErrors(errors))

        return formIsValid;
    }

    const setStatePreferenceAction = (state, id) => {
        if(!state){
            store.dispatch(addPreference(Number(id)))
        }else{
            store.dispatch(removePreference(Number(id)))
        }
    }

    const checkPreferences = (id) => {
        for(let pr of preferences){
            if(Number(pr.id) === Number(id)){
                return true
            }
        }
        return false
    }

    if(!params.id ||(params.id && Object.keys(exercise).length > 0)){
        return (
            <>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container spacing={2}>
                    <PageTitle title={context === "edit" ? "Editer une activité" : "Ajouter une activité"} />
                    <Grid item display="flex" justifyContent="right" xs={8} md={6}>
                        <FormControl size="small" sx={{ mt:2, width: '150px' }}>
                            <InputLabel id="lblLang">Langue</InputLabel>
                            <Select labelId="lblLang" id="lang" name="lang" label="Langue" onChange={switchLang} value={lang || ""}>
                                <MenuItem value="de">🇩🇪 Allemand</MenuItem>
                                <MenuItem value="en">🇬🇧 Anglais</MenuItem>
                                <MenuItem value="au">🇦🇺 Australien</MenuItem>
                                <MenuItem value="fr">🇫🇷 Français</MenuItem>
                                <MenuItem value="it">🇮🇹 Italien</MenuItem>
                                <MenuItem value="nl">🇳🇱 Néerlandais</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid component="form" item  sx={{ m: 2 }}  xs={12} md={4} onSubmit={handleSubmit} noValidate>
                        <Grid item xs={6}>
                            <FormControl sx={{alignItems: 'center', m: 1 }} >
                                <Typography component="legend">Actif</Typography>
                                <Switch checked={enabled} name="enabled" onChange={(e)=>{
                                    if(enabled){
                                        store.dispatch(setEnabled(false))
                                        return
                                    }
                                    store.dispatch(setEnabled(true))
                                }}  color="success"/>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl sx={{ m: 1 }} fullWidth variant="standard">
                                <TextField required {...(validationErrors['name'] && { error: true })}  helpertext={validationErrors['name']} id="name" label="Nom" name="name" onChange={(e)=>{
                                    if(e.target.value !== ''){
                                        store.dispatch(setValidationErrors(removeErrorsFields(['name'], validationErrors)))
                                    }
                                    store.dispatch(setName(e.target.value))
                                }} value={name || ""} focused={true} />
                            </FormControl>
                        </Grid>
                        <Grid container>
                            <Grid item  xs={6}>
                                <FormControl sx={{ alignItems: 'center', m: 1 }} fullWidth {...(validationErrors['level'] && { error: true })}>
                                    <Typography {...(validationErrors['level'] && { sx: { color: 'error.main'  }})} component="legend">Difficulté</Typography>
                                    <Rating max={3} size="large" name="level" onChange={(e)=>{
                                        store.dispatch(setLevel(Number(e.target.value)))
                                    }} value={Number(level)} />
                                    <FormHelperText>{validationErrors['level']}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{alignItems: 'center', m: 1 }} >
                                    <Typography component="legend">Inclusif</Typography>
                                    <Switch checked={inclusive} name="inclusive" onChange={()=>{
                                        if(inclusive){
                                            store.dispatch(setInclusive(false))
                                            return
                                        }
                                        store.dispatch(setInclusive(true))
                                    }}  color="success"/>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ m: 1 }} fullWidth variant="standard">
                                <TextField required {...(validationErrors['videoUrl'] && { error: true })}  helpertext={validationErrors['videoUrl']} id="videoUrl" label="Lien vidéo" name="videoUrl" 
                                onChange={(e) => {
                                    if(e.target.value !== ''){
                                        store.dispatch(setValidationErrors(removeErrorsFields(['videoUrl'], validationErrors)))
                                    }
                                    changeEmbedVideo(e.target.value);
                                    store.dispatch(setVideoUrl(e.target.value))
                                    }
                                } value={videoUrl || ""} focused={true} />
                            </FormControl>
                        </Grid>
                        {videoEmbedUrl !== "" && 
                        <Grid item sx={{ ml: 1, alignItems: 'center' }}>
                            <iframe title="video de l'exercise" src={videoEmbedUrl} style={{width: "100%", minHeight:"300px"}} frameBorder="0" allow="fullscreen; picture-in-picture"></iframe>
                        </Grid>
                        }
                        <Grid container>
                            <Grid item xs={6}>
                                
                            <FormControl sx={{ m: 1 }} fullWidth variant="standard" {...(validationErrors['picture'] && { error: true })}>
                                <Typography {...(validationErrors['picture'] && { sx: { color: 'error.main'  }})} component="legend">Illustration muscles</Typography>
                                <label htmlFor="picture">
                                    <Input sx={{ display: 'none' }} name="picture" accept="image/*" id="picture" onChange={(e) => {
                                        previewImage(e)
                                        }} type="file" />
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                                <FormHelperText>{validationErrors['picture']}</FormHelperText>
                            </FormControl>
                            </Grid>
                            <Grid item style={{ 'textAlign': 'center' }} xs={6}>
                                <img style={{'maxHeight': '70px'}} alt="" src={musclesImg} name="muscles" />
                            </Grid>
                        </Grid>
                        <Grid>
                            <FormControl sx={{ m: 1 }} fullWidth>
                                <TextField required {...(validationErrors['description'] && { error: true })}  helpertext={validationErrors['description']} id="description" InputLabelProps={{ shrink: true }} label="Description" name="description" minRows={7} multiline 
                                onChange={(e)=>{
                                    if(e.target.value !== ''){
                                        store.dispatch(setValidationErrors(removeErrorsFields(['description'], validationErrors)))
                                    }
                                    store.dispatch(setDescription(e.target.value))
                                }} value={description || ""} />
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl sx={{ m: 1 }} fullWidth variant="standard">
                                <Button type="submit" variant="contained">Enregistrer</Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{m: 2}}>
                        <Box sx={{border: 1, borderColor: '#9e9e9e63', borderRadius: '5px', p: 5}}>
                            <Box display="flex">
                                <Box flex="2">
                                    <FormControl sx={{alignItems: 'center', m: 1 }} >
                                        <Typography {...(validationErrors['pointsByRep'] && { sx: { color: 'error.main'  }})} component="legend">Répétitions</Typography>
                                        <Switch checked={rep} name="rep" 
                                            onChange={()=>{
                                                if(rep){
                                                    store.dispatch(setRep(false))
                                                    store.dispatch(setChrono(true))
                                                    store.dispatch(setPointsByRep(0))
                                                    return
                                                }
                                                store.dispatch(setValidationErrors(removeErrorsFields(['pointsByRep', 'pointsByTime'], validationErrors)))
                                                store.dispatch(setRep(true))
                                                store.dispatch(setChrono(false))
                                                store.dispatch(setPointsByTime(0))
                                                store.dispatch(setMaxTime(0))
                                            }}
                                            color="success"
                                        />
                                    </FormControl>
                                </Box>
                                <Box flex="3">
                                    <FormControl sx={{ m: 1 }} fullWidth variant="standard">
                                        <Typography component="legend" sx={{ width: '100px' }}>Points obtenus par répétition</Typography>
                                        <TextField required id="points-rep" label="" name="pointsByRep" sx={{width: '100px'}} 
                                            onChange={(e)=>{
                                                if(!isNaN(Number(e.target.value))){
                                                    store.dispatch(setPointsByRep(Number(e.target.value)))
                                                }
                                            }} value={pointsByRep || ""} disabled={!rep} {...(validationErrors['pointsByRep'] && { error: true })} />
                                    </FormControl>                                
                                </Box>
                            </Box>
                            <Divider orientation="horizontal" flexItem>
                                OU
                            </Divider>
                            <Box display="flex">
                                <Box flex="2">
                                    <FormControl sx={{alignItems: 'center', m: 1 }}>
                                        <Typography {...(validationErrors['pointsByTime'] && { sx: { color: 'error.main'  }})} component="legend">Chronomètre</Typography>
                                        <Switch checked={chrono} name="chrono" 
                                            onChange={(e)=>{
                                                if(chrono){
                                                    store.dispatch(setRep(true))
                                                    store.dispatch(setChrono(false))
                                                    store.dispatch(setPointsByTime(0))
                                                    store.dispatch(setMaxTime(0))
                                                    return
                                                }
                                                store.dispatch(setValidationErrors(removeErrorsFields(['pointsByRep', 'pointsByTime'], validationErrors)))
                                                store.dispatch(setRep(false))
                                                store.dispatch(setChrono(true))
                                                store.dispatch(setPointsByRep(0))


                                            }}
                                            color="success"
                                        />
                                    </FormControl>
                                </Box>
                                <Box flex="3" sx={{display: 'flex', flexDirection:'column'}}>
                                    <FormControl sx={{ m: 1 }} fullWidth variant="standard">
                                        <Typography component="legend">Points obtenus par temps passé</Typography>
                                        <TextField required id="pointsByTime" label="" name="pointsByTime" sx={{width: '100px'}} 
                                            onChange={(e)=>{
                                                if(!isNaN(Number(e.target.value))){
                                                    store.dispatch(setPointsByTime(Number(e.target.value)))
                                                }
                                            }} value={pointsByTime || ""} disabled={!chrono} {...(validationErrors['pointsByTime'] && { error: true })}/>
                                    </FormControl> 
                                    <FormControl sx={{ m: 1 }} fullWidth variant="standard">
                                        <Typography component="legend">Temps maximum (facultatif)</Typography>
                                        <TextField required id="max-time" label="" name="maxTime" sx={{width: '100px'}} 
                                            onChange={(e)=>{
                                                if(!isNaN(Number(e.target.value))){
                                                    store.dispatch(setMaxTime(e.target.value === '' ? 0 : Number(e.target.value)))
                                                }else{
                                                    store.dispatch(setMaxTime(0))
                                                }
                                            }} value={maxTime || ""} disabled={!chrono}/>
                                    </FormControl> 
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{mt: 3, p: 1}}>
                            <Typography component="h2" sx={{fontSize: '27px', fontWeight: 'bold'}}>Préférences Ludo-sportives</Typography>
                            <FormControl sx={preferenceCssForm}>
                                <Switch checked={checkPreferences(1)} mt="1" name="family" id={"1"} onChange={(e)=>{
                                    store.dispatch(setPrefFamily(!prefFamily))
                                    setStatePreferenceAction(prefFamily, e.target.id)
                                }} color="success"/>
                                <IconFamily width="27px" height="27px"/>
                                <Typography component="legend" sx={preferenceCssTypo}>Faire une activité ludo-sportive en famille</Typography>
                            </FormControl>
                            <FormControl sx={preferenceCssForm}>
                                <Switch checked={checkPreferences(2)} mt="1" name="physical-condition" id={"2"} onChange={(e)=>{
                                    store.dispatch(setPrefPhysicalCondition(!prefPhysicalCondition))
                                    setStatePreferenceAction(prefPhysicalCondition, e.target.id)
                                }}  color="success"/>
                                <IconSneaker width="27px" height="27px"/>
                                <Typography component="legend" sx={preferenceCssTypo}>Améliorer ma condition physique</Typography>
                            </FormControl>
                            <FormControl sx={preferenceCssForm}>
                                <Switch checked={checkPreferences(3)} mt="1" name="shape" id={"3"} onChange={(e)=>{
                                    store.dispatch(setPrefShape(!prefShape))
                                    setStatePreferenceAction(prefShape, e.target.id)
                                }}  color="success"/>
                                <IconHeart width="27px" height="27px"/>
                                <Typography component="legend" sx={preferenceCssTypo}>Me maintenir en forme</Typography>
                            </FormControl>
                            <FormControl sx={preferenceCssForm}>
                                <Switch checked={checkPreferences(4)} mt="1" name="muscle-mass" id={"4"} onChange={(e)=>{
                                    store.dispatch(setPrefMuscleMass(!prefMuscleMass))
                                    setStatePreferenceAction(prefMuscleMass, e.target.id)
                                }}  color="success"/>
                                <IconMuscle width="27px" height="27px"/>
                                <Typography component="legend" sx={preferenceCssTypo}>Développer ma masse musculaire</Typography>
                            </FormControl>
                            <FormControl sx={preferenceCssForm}>
                                <Switch checked={checkPreferences(5)} mt="1" name="fun" id={"5"} onChange={(e)=>{
                                    store.dispatch(setPrefFun(!prefFun))
                                    setStatePreferenceAction(prefFun, e.target.id)
                                }}  color="success"/>
                                <IconFun width="27px" height="27px"/>
                                <Typography component="legend" sx={preferenceCssTypo}>Pratiquer une activité physique fun</Typography>
                            </FormControl>
                            <FormControl sx={preferenceCssForm}>
                                <Switch checked={checkPreferences(6)} mt="1" name="group" id={"6"} onChange={(e)=>{
                                    store.dispatch(setPrefGroup(!prefGroup))
                                    setStatePreferenceAction(prefGroup, e.target.id)
                                }}  color="success"/>
                                <IconPeoples width="27px" height="27px"/>
                                <Typography component="legend" sx={preferenceCssTypo}>Pratiquer des activités en groupe</Typography>
                            </FormControl>
                            <FormControl sx={preferenceCssForm}>
                                <Switch checked={checkPreferences(7)} mt="1" name="wheelchair" id={"7"} onChange={(e)=>{
                                    store.dispatch(setPrefWheelchair(!prefWheelchair))
                                    setStatePreferenceAction(prefWheelchair, e.target.id)
                                }}  color="success"/>
                                <IconWheelchair width="27px" height="27px"/>
                                <Typography component="legend" sx={preferenceCssTypo}>Profiter d'activités en fauteuil roulant</Typography>
                            </FormControl>
                            <FormControl sx={preferenceCssForm}>
                                <Switch checked={checkPreferences(8)} mt="1" name="challenge" id={"8"} onChange={(e)=>{
                                    store.dispatch(setPrefChallenge(!prefChallenge))
                                    setStatePreferenceAction(prefChallenge, e.target.id)
                                }}  color="success"/>
                                <IconAward width="27px" height="27px"/>
                                <Typography component="legend" sx={preferenceCssTypo}>Relever des challenges en équipe</Typography>
                            </FormControl>
                            <FormControl sx={preferenceCssForm}>
                                <Switch checked={checkPreferences(9)} mt="1" name="thrill" id={"9"} onChange={(e)=>{
                                    store.dispatch(setPrefThrill(!prefThrill))
                                    setStatePreferenceAction(prefThrill, e.target.id)
                                }}  color="success"/>
                                <IconLightning width="27px" height="27px"/>
                                <Typography component="legend" sx={preferenceCssTypo}>Ressentir des sensations fortes</Typography>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{m: 2}}>
                        <Box>
                            <ApparatusCard id={searchParams.get('apparatus')} />
                        </Box>
                    </Grid>
                </Grid>
            </>
        );
    }
    else{
        return (
            <>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
            );
    }
}