import * as React from 'react'
import { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Backdrop, CircularProgress, Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { getDashboard } from "../../api/DashboardAPI"
import { setDash } from '../../redux/Dashboard/DashboardSlice'
import { store } from '../../redux/Store'
import { setBackdrop } from '../../redux/App/AppSlice'
import {ReactComponent as IconUsersDashboard} from '../../_images/icons/dashboard/users_dashboard.svg'
import {ReactComponent as IconTimerDashboard} from '../../_images/icons/dashboard/timer_dashboard.svg'
import {ReactComponent as IconMuscleDashboard} from '../../_images/icons/dashboard/muscle_dashboard.svg'
import {ReactComponent as IconApparatusDashboard} from '../../_images/icons/dashboard/agres_dashboard.svg'
import PageTitle from '../../components/PageTitle'

const stylesD = {
    bordersRadius: {
        borderRadius: "1rem"
    },
    boxes: {
        boxShadow: "0px 0px 15px -3px rgba(0,0,0,0.1)",
        backgroundColor: "#fff"
    },
    flexSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    textData: {
        fontWeight: '600', 
        textAlign: 'center'
    }
}
export default function Dashboard(props) {

    const backdrop = useSelector((state)=>state.app.backdrop)
    const dash = useSelector((state)=>state.dashboard.dash)

    useEffect(() => {

        async function getDash() {

            store.dispatch(setBackdrop(true));
            let oDash = await getDashboard(); 
            store.dispatch(setDash(oDash));
            store.dispatch(setBackdrop(false));
        }

        getDash();

    }, []);

    const displayTime = (time) => {
        let hour = Math.floor(time / 3600)
        let minutes = Math.floor(time / 60)
        let seconds = Math.floor(time % 60)
        return displayPartTime(hour) + ':' + displayPartTime(minutes) + ':' + displayPartTime(seconds)
    }

    const displayPartTime = (time) => {
        return (Number(time) === 0 ? '00' : (Number(time) < 10 ? ('0'+ time) : time))
    }

    return (
        <>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop} >
                <CircularProgress color="inherit" />
            </Backdrop>

            { dash && <>
                    <PageTitle title="Dashboard" flex="1" />
                    

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <Box sx={{p: 3, mb: 3, backgroundColor: '#FCF8EF', ...stylesD.bordersRadius}}>
                                <Box sx={{p: 3, ...stylesD.bordersRadius, ...stylesD.boxes}}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <Typography sx={{ fontSize: 17, fontWeight: '500' }} >
                                                Nombre d'utilisateurs
                                            </Typography>
                                            <IconUsersDashboard width="40px" height="40px"/>
                                            <Typography variant="h4" component="div" sx={{fontWeight: '900'}}>
                                                { dash.accounts }
                                            </Typography>
                                    </Box>    
                                    <Box sx={{...stylesD.bordersRadius, ...stylesD.boxes, mt: 1, p: 2, ...stylesD.flexSpaceBetween}}>
                                        <Typography sx={{ fontSize: 13, pt: 1 }}>
                                            Inscrits sans compte validé
                                        </Typography>
                                        <Typography variant="h5" component="div" sx={{fontWeight: '700'}}>
                                            { dash.unRegistered }
                                        </Typography> 
                                    </Box>
                                    <Box sx={{...stylesD.bordersRadius, ...stylesD.boxes, mt: 2, p: 2, ...stylesD.flexSpaceBetween}}>
                                        <Typography sx={{ fontSize: 13, pt: 1 }}>
                                            Inscrits avec compte validé
                                        </Typography>
                                        <Typography variant="h5" component="div" sx={{fontWeight: '700'}}>
                                            { dash.registered }
                                        </Typography> 
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{p: 3, backgroundColor: '#FCF8EF', ...stylesD.bordersRadius}}>
                                <Box sx={{...stylesD.bordersRadius, ...stylesD.boxes, p: 3}}>
                                    <Box sx={{display: 'flex'}}>
                                        <IconTimerDashboard width="20px" height="20px"/>
                                        <Typography sx={{ fontSize: 17, fontWeight: '500', ml: 1 }}>Durée</Typography>                                    
                                    </Box>
                                    <Grid container sx={{mt: 2}}>
                                        <Grid item xs={6}>
                                            <Box sx={{p:2,m:1, ...stylesD.bordersRadius, ...stylesD.boxes}}>
                                                <Typography sx={{ fontSize: 13, textAlign: 'center'}}>Moyenne des activités</Typography>
                                                <Typography variant="h5" component="div" sx={stylesD.textData}>
                                                { displayTime(70) }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box sx={{p:2,m:1, ...stylesD.bordersRadius, ...stylesD.boxes}}>
                                                <Typography sx={{ fontSize: 13, textAlign: 'center'}}>Toutes des activités</Typography>
                                                <Typography variant="h5" component="div" sx={stylesD.textData}>
                                                    { displayTime(dash.total_workout) }
                                                </Typography>
                                            </Box>
                                        </Grid>                                    
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box sx={{p: 3,mb: 3, backgroundColor: '#FCF8EF', ...stylesD.bordersRadius}}>
                                <Box sx={{...stylesD.bordersRadius, ...stylesD.boxes, p: 3}}>
                                    <Box sx={{display: 'flex'}}>
                                    <IconApparatusDashboard width="20px" height="20px"/>
                                        <Typography sx={{ fontSize: 17, fontWeight: '500', ml: 1 }}>Agrès</Typography>                                    
                                    </Box>
                                    <Grid container sx={{mt: 2}}>
                                        <Grid item xs={12}>
                                            <Box sx={{px:4,py: 2, m:1, ...stylesD.bordersRadius, ...stylesD.boxes, ...stylesD.flexSpaceBetween}}>
                                                <Typography sx={{ fontSize: 13, textAlign: 'center', mt: 1}}>Nombre d'agrès</Typography>
                                                <Typography variant="h5" component="div" sx={stylesD.textData}>
                                                    { dash.apparatuses }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{px:4,py: 2,m:1, ...stylesD.bordersRadius, ...stylesD.boxes, ...stylesD.flexSpaceBetween}}>
                                                <Typography sx={{ fontSize: 13, textAlign: 'center'}}>Agrès géolocalisés</Typography>
                                                <Typography variant="h5" component="div" sx={stylesD.textData}>
                                                    { dash.apparatusesLocated }
                                                </Typography>
                                            </Box>
                                        </Grid>                                    
                                    </Grid>
                                </Box>
                            </Box>
                            <Box sx={{p: 3, backgroundColor: '#FCF8EF', ...stylesD.bordersRadius}}>
                                <Box sx={{...stylesD.bordersRadius, ...stylesD.boxes, p: 3}}>
                                    <Box sx={{display: 'flex'}}>
                                    <IconMuscleDashboard width="20px" height="20px"/>
                                        <Typography sx={{ fontSize: 17, fontWeight: '500', ml: 1 }}>Entrainements</Typography>                                    
                                    </Box>
                                    <Grid container sx={{mt: 2}}>
                                        <Grid item xs={12}>
                                            <Box sx={{px:4,py: 2, m:1, ...stylesD.bordersRadius, ...stylesD.boxes, ...stylesD.flexSpaceBetween}}>
                                                <Typography sx={{ fontSize: 13, textAlign: 'center', mt: 1}}>Activités réalisées</Typography>
                                                <Typography variant="h5" component="div" sx={stylesD.textData}>
                                                    { dash.workouts }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{px:4,py: 2,m:1, ...stylesD.bordersRadius, ...stylesD.boxes, ...stylesD.flexSpaceBetween}}>
                                                <Typography sx={{ fontSize: 13, textAlign: 'center'}}>Programmes réalisés</Typography>
                                                <Typography variant="h5" component="div" sx={stylesD.textData}>
                                                    { dash.programs }
                                                </Typography>
                                            </Box>
                                        </Grid>                                    
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
}
