import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { login } from '../../api/LoginAPI';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { setHasError } from '../../redux/Login/LoginSlice';
import { store } from '../../redux/Store';


export default function Login(props) {

    let navigate = useNavigate();
    const hasError = useSelector((state)=>state.login.hasError)

    localStorage.removeItem("token");

    const handleLogin = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        await login(formData.get('email'), formData.get('password')).then((response) => {
            if(response.data.isAdmin){
                localStorage.setItem("token", response.data.token);
                navigate("/");
            }
            else
                store.dispatch(setHasError(true));
        },
        error => {
            store.dispatch(setHasError(true));
        });

    };

    return ( 
        <Container component="main" maxWidth="xs">
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                
                <img src="/logo-actifun.png" height="40" alt="actifun"/>
                <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                    <TextField margin="normal" required fullWidth id="email" label="Email" name="email" autoComplete="email" autoFocus />
                    <TextField margin="normal" required fullWidth name="password" label="Mot de passe" type="password" id="password" autoComplete="current-password" />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Se connecter</Button>
                </Box>
            </Box>


            <Snackbar open={hasError} autoHideDuration={6000} onClose={() => {store.dispatch(setHasError(false));}}>
                <Alert onClose={() => {store.dispatch(setHasError(false));}} severity="error" sx={{ width: '100%' }} elevation={6}  variant="filled" >
                    Formulaire invalide, vérifiez les informations saisies. 
                </Alert>
            </Snackbar>
        </Container>
        );
    }