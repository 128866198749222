import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { getApparatus } from '../../api/ApparatusAPI';
import {Link as RouterLink} from 'react-router-dom';



export default function ApparatusCard(props) {

	const [apparatus, setApparatus] = useState({});
	const [image, setImage] = useState("");

	useEffect(() => {
        async function getCurrentApparatus() {
            const app = await getApparatus(props.id);
            setImage(app.picture)
            setApparatus(app);
        }

        if(props.id)
            getCurrentApparatus();
    }, [props.id]);

	if(image === "")
		return (<Card><CardContent><Typography variant="body2" color="text.primary">Chargement...</Typography></CardContent></Card>)
	else
	  	return (
		    <Card>
		    	<Link component={RouterLink} to={"/apparatus/" + apparatus.id} underline="none">
				<CardHeader title={apparatus.name} subheader={apparatus.reference} />
				<CardMedia component="img" sx={{objectFit: 'contain'}} height="194" image={image} alt="Illustration agrès" />
				<CardContent>
					<Typography variant="body2" color="text.primary">
					Gamme: 
					</Typography>
					<Typography variant="body1" color="text.secondary">
					{apparatus.productRange}
					</Typography>
				</CardContent>
				</Link>
		    </Card>
		);
}
