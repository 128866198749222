import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    exercise: {},
    videoUrl: "",
    videoEmbedUrl: "",
    isFilePicked: false,
    musclesImg: "",
    validationErrors: {},
    enabled: false,
    name: "",
    description: "",
    level: null,
    inclusive: false,
    rep: true,
    pointsByRep: 0,
    chrono: false,
    pointsByTime: 0,
    maxTime: 0,
    prefFamily: false,
    prefPhysicalCondition: false,
    prefShape: false,
    prefMuscleMass: false,
    prefFun: false,
    prefGroup: false,
    prefWheelchair: false,
    prefChallenge: false,
    prefThrill: false,
    preferences: []
}

export const ExerciceSlice = createSlice({
    name: 'exercice',
    initialState,
    reducers: {
        setExercise: (state, action) => {
            state.exercise = action.payload
        },
        setVideoUrl: (state, action) => {
            state.videoUrl = action.payload
        },
        setVideoEmbedUrl: (state, action) => {
            state.videoEmbedUrl = action.payload
        },
        setIsFilePicked: (state, action) => {
            state.isFilePicked = action.payload
        },
        setMusclesImg: (state, action) => {
            state.musclesImg = action.payload
        },
        setFormData: (state, action) => {
            state.formData = action.payload
        },
        setValidationErrors: (state, action) => {
            state.validationErrors = action.payload
        },
        setRep: (state, action) => {
            state.rep = action.payload
        },
        setPointsByRep: (state, action) => {
            state.pointsByRep = action.payload
        },
        setChrono: (state, action) => {
            state.chrono = action.payload
        },
        setPointsByTime: (state, action) => {
            state.pointsByTime = action.payload
        },
        setMaxTime: (state, action) => {
            state.maxTime = action.payload
        },
        setEnabled: (state, action) => {
            state.enabled = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setDescription: (state, action) => {
            state.description = action.payload
        },
        setLevel: (state, action) => {
            state.level = action.payload
        },
        setInclusive: (state, action) => {
            state.inclusive = action.payload
        },
        setPrefFamily: (state, action) => {
            state.prefFamily = action.payload
        },
        setPrefPhysicalCondition: (state, action) => {
            state.prefPhysicalCondition = action.payload
        },
        setPrefShape: (state, action) => {
            state.prefShape = action.payload
        },
        setPrefMuscleMass: (state, action) => {
            state.prefMuscleMass = action.payload
        },
        setPrefFun: (state, action) => {
            state.prefFun = action.payload
        },
        setPrefGroup: (state, action) => {
            state.prefGroup = action.payload
        },
        setPrefWheelchair: (state, action) => {
            state.prefWheelchair = action.payload
        },
        setPrefChallenge: (state, action) => {
            state.prefChallenge = action.payload
        },
        setPrefThrill: (state, action) => {
            state.prefThrill = action.payload
        },
        setPreferences: (state, action) => {
            state.preferences = action.payload
        },
        addPreference: (state, action) => {
            let prefExist = false
            for(let pr of state.preferences){
                if(Number(pr.id) === Number(action.payload)){
                    prefExist = true
                }
            }

            if(!prefExist){
                state.preferences.push({
                    id: Number(action.payload)
                })
            }

        },
        removePreference: (state, action) => {
            let newPreferences = []

            for(let pr of state.preferences) {
                if(Number(pr.id) !== Number(action.payload)){
                    newPreferences.push({id: pr.id})
                }
            }

            state.preferences = newPreferences
        }
    }
})

export const {
    setExercise,
    setVideoUrl,
    setVideoEmbedUrl,
    setIsFilePicked,
    setMusclesImg,
    setValidationErrors,
    setRep,
    setPointsByRep,
    setChrono,
    setPointsByTime,
    setMaxTime,
    setEnabled,
    setName,
    setDescription,
    setLevel,
    setInclusive,
    setPrefFamily,
    setPrefPhysicalCondition,
    setPrefShape,
    setPrefMuscleMass,
    setPrefFun,
    setPrefGroup,
    setPrefWheelchair,
    setPrefChallenge,
    setPrefThrill,
    setPreferences,
    addPreference,
    removePreference
} = ExerciceSlice.actions
export default ExerciceSlice.reducer