import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { validateUser } from "../services/userService";
import { useParams } from "react-router-dom";
import appconfig from "../config/appconfig.json";

export default function Welcome(props) {

	const params = useParams();
	const translations = appconfig.translations[1][params.lang] ?? appconfig.translations[1]["fr"];
    const enableUser = async () => {
    	
        let userData = {};
        userData.token = params.token;

        validateUser(userData).then((response) => {
            
	        },
	        error => {   
	        });
        
    };

    enableUser();

    return ( 
        <Container component="main">
            <Grid sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src="/logo-actifun.png" height="80" alt="actifun"/>
                <Grid item xs={12}>
	                <Typography variant="h5" sx={{mb:5, mt: 5, width: '100%'}}>
	                	{translations['title']}
	                </Typography>

	                
	    			<Typography variant="body1" sx={{  mb:2, mt: 2, width: '100%' }}>
	                	{translations['welcome_message'].split("\n").map((i, key) => {
                        return <div key={key}>{i}</div>;
                    })}
	                </Typography>
                </Grid>
            </Grid>
        </Container>
        );
    }