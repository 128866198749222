import axios from "axios";
import authHeader from "../services/authHeader";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export function getExercices(lang) {
    try { 
		return axios.get(apiUrl + 'exercises', authHeader(lang)).then(function (response){return response.data});
    } catch (error) {
        return null;
    }
}

export function getExercise(id, lang) {
    try { 
        return axios.get(apiUrl + 'exercises/'+id, authHeader(lang)).then(function (response){
            return response.data
        });
    } catch (error) {
        return null;
    }
}

export function patchExercise(id, formData, lang) {
    try { 
        return axios.patch(apiUrl + 'exercises/'+id, formData, authHeader(lang)).then(function (response){return response.data}).catch(function (error) {return error});
    } catch (error) {
        return null;
    }
}

export function createExercise(formData, lang) {
    try { 
        return axios.post(apiUrl + 'exercises', formData, authHeader(lang)).then(function (response){return response.data}).catch(function (error) {return error});
    } catch (error) {
        return null;
    }
}

export function deleteExercise(id_exercise) {
    try { 
       return axios.delete(apiUrl + 'exercises/'+id_exercise, authHeader()).then(function (response){return {status: 'success', data: response.data}}).catch((error) => ({status: 'error', data: error}));
    } catch (error) {
        return {status: 'error', data: error};
    }
}