import React from 'react'

export default function Banner() {
  return (
    <div className={`d-flex justify-content-between bg-danger`}
        style={{fontSize: '10px', height: '15px', width: '100%', position: 'fixed', top: '0px', left: '0px', padding: '5px', display: 'flex', justifyContent: 'space-between', backgroundColor: 'red' }}>
          <p style={{color: 'white', fontWeight: 'bold', margin: '0px'}}>Vous êtes dans l'environnement de pré-production. Cet environnement est utilisé pour les tests. Pour accéder au back-office en production, allez à l'adresse suivante : <a href="https://actifunbo.proludic.com/" rel="noreferrer" target="_blank">Acti'Fun</a> </p>
      </div>
  )
}
