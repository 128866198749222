export function isNotEmpty(value){
    return value !== undefined && value !== null && value !== '' && value !== false && value !== 0
}

export const isUrl = string => {
    try { return Boolean(new URL(string)); }
    catch(e){ return false; }
}

export function validateVimeoURL(url) {
    return /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/.test(url);
}

export const removeErrorsFields = (errorNames, validationErrors) => {
    let newErrors = Object.keys({...validationErrors})
                    .filter(e => {
                        let result = true
                        for(let en of errorNames){
                            result = result && e !== en
                        }
                        return result
                    })
                    .reduce((obj, e) => {
                        obj[e] = validationErrors[e];
                        return obj;
                    }, {});
                    return {...newErrors}
}