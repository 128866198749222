import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    hasError: false,
    error: "",
    values: {
	    password: '',
	    showPassword: false,
	  }
}

export const LoginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setHasError: (state, action) => {
            state.hasError = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setValues: (state, action) => {
            state.values = action.payload
        }
    }
})

export const {setHasError, setError, setValues} = LoginSlice.actions
export default LoginSlice.reducer
