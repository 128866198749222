import React, { useEffect } from 'react'
import { DataGrid, frFR } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Select, MenuItem, Backdrop, FormControl, InputLabel, CircularProgress,  Grid, Box } from '@mui/material'
import { getApparatusesBySite } from '../../api/ApparatusAPI'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PageTitle from '../../components/PageTitle'
import {useNavigate} from "react-router-dom"
import { getSite, patchSite } from '../../api/SiteAPI'
import {setRows, setSite, setNameSite, setLang, setIdSiteToDelete, setOpenModalDeleteSite, setLoading} from '../../redux/Site/SiteSlice'
import { store } from '../../redux/Store'
import { setBackdrop, addMessageAlert, setOpenAlert } from '../../redux/App/AppSlice'
import TextField from '@mui/material/TextField'
import { isNotEmpty } from '../../services/AppServices'
import Button from '@mui/material/Button'
import ModalDelete from '../../components/modal/ModalDelete'
import { deleteSiteService } from '../../services/SiteServices'
import { deleteApparatusService } from '../../services/ApparatusServices'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import { setIdApparatusToDelete, setOpenModalDeleteApparatus } from '../../redux/Apparatus/ApparatusSlice'
import messagesconfig from "../../config/messagesconfig.json"
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import L from 'leaflet';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReactDOMServer from 'react-dom/server';
import 'leaflet/dist/leaflet.css';
import TooltipGrid from '../../components/shared/TooltipGrid'

export default function EditSite() {

       let navigate = useNavigate();
       const params = useParams();

       const backdrop = useSelector((state)=>state.app.backdrop)
       const lang = useSelector((state)=>state.site.lang)
       const rows = useSelector((state)=>state.site.rows)
       const site = useSelector((state)=>state.site.site)
       const nameSite = useSelector((state)=>state.site.nameSite)
       const openModalDeleteSite = useSelector((state) => state.site.openModalDeleteSite)
       const openModalDeleteApparatus = useSelector((state) => state.apparatus.openModalDeleteApparatus)
       const idSiteToDelete = useSelector((state)=>state.site.idSiteToDelete)
       const idApparatusToDelete = useSelector((state)=>state.apparatus.idApparatusToDelete)

       const locationIcon = L.divIcon({
        className: 'custom-icon',
        html: ReactDOMServer.renderToString(<LocationOnIcon style={{ fontSize: 50, color: '#F98407' }} />),
        iconSize: [50, 50],
        iconAnchor: [25, 50]
       });

       useEffect(() => {

            async function getCurrentSite() {
                const app = await getSite(params.id, lang);
                const apparatuses = await getApparatusesBySite(params.id);
                store.dispatch(setNameSite(app.name))
                store.dispatch(setSite(app));
                store.dispatch(setRows(apparatuses));
                store.dispatch(setBackdrop(false));
            }

            store.dispatch(setIdSiteToDelete(Number(params.id)))
            if(isNotEmpty(params.id)) getCurrentSite(); 

            return ()=>{
                store.dispatch(setIdSiteToDelete(null))
                store.dispatch(setNameSite(''))
                store.dispatch(setSite({}))
            }
            // eslint-disable-next-line
       }, [lang, params.id]);
   
       const actcolumns = [
           { field: 'id', headerName: 'ID', flex: 1, renderHeader: () => (
                <strong>{"ID"}</strong>
            )},
           {
               field: 'picture',
               flex: 1,
               align: 'center',
               headerName: 'Image',
               renderHeader: () => (
                    <TooltipGrid value={"Image"} jsx={<strong>{"Image"}</strong>} />
                ),
               renderCell: (params) => (
                   <img alt="apparatus" width='20%' src={params.value} />
               ),
           }, 
           { field: 'productRange', headerName: 'Gamme', flex: 1, 
           renderHeader: () => (
                <TooltipGrid value={"Gamme"} jsx={<strong>{"Gamme"}</strong>} />
            )
           },
           { field: 'reference', headerName: 'Référence', flex: 1, 
            renderHeader: () => (
                <TooltipGrid value={"Référence"} jsx={<strong>{"Référence"}</strong>} />
            )
            },
           { field: 'name', headerName: 'Nom', flex: 1, 
            renderHeader: () => (
                <TooltipGrid value={"Nom"} jsx={<strong>{"Nom"}</strong>} />
            )
            },
           {
               field: 'enabled',
               flex: 1,
               headerName: 'Actif',
               renderHeader: () => (
                    <TooltipGrid value={"Actif"} jsx={<strong>{"Actif"}</strong>} />
                ),
               renderCell: (params) => {
                   if(params.value)
                       return <CheckCircleOutlinedIcon color="success" />
                   else
                       return <CancelOutlinedIcon color="error" />
               },
           },
           {field: 'delete',headerName: 'Supprimer',flex: 1, 
           renderHeader: () => (
                <TooltipGrid value={"Supprimer"} jsx={<strong>{"Supprimer"}</strong>} />
            ),
           renderCell: (params)=>(
                 <IconButton aria-label="delete" onClick={(e)=>{
                        e.stopPropagation()
                        store.dispatch(setIdApparatusToDelete(params.row.id))
                        handleClickOpen()
                    }}>
                    <ClearIcon sx={{color: 'red'}}/>
                </IconButton>
                )
            }
       ];

       const handleClickOpen = () => {
        store.dispatch(setOpenModalDeleteApparatus(true))
    }

       const openRow = event => {
           navigate({pathname: "/apparatus/"+ event.currentTarget.dataset.id })
       }

       const updateName = async (e) => {
            e.preventDefault()
            store.dispatch(setBackdrop(true))
            let result = await patchSite(site.id, nameSite, lang)
            store.dispatch(setBackdrop(false))
            let typeAlert = ''
            let message = ''
            if(result.status === 'success'){
                typeAlert = 'success'
                message = messagesconfig.EditSite.SUCCESS_UPDATE_SITE
            }else{
                typeAlert = 'error'
                message = messagesconfig.EditSite.ERROR_UPDATE_SITE
            }
            store.dispatch(setOpenAlert(true))
            store.dispatch(addMessageAlert({message: message, type: typeAlert}))
       }

       const switchLang = e => {
            store.dispatch(setLang(e.target.value))
        }

       if(!params.id ||(params.id && Object.keys(site).length > 0)) {
           return (
               <>
                   <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop} >
                       <CircularProgress color="inherit" />
                   </Backdrop>
                   <Grid container spacing={2} display="flex">
                       <PageTitle title="Editer un site" flex="1" />
                       <Box flex="1" display="flex" justifyContent="space-evenly">
                            <Button 
                                variant="contained" 
                                sx={{color: 'black',backgroundColor: 'lightgrey', width: '180px', height: '38px', mt: 5}}
                                onClick={()=>{store.dispatch(setOpenModalDeleteSite(true))}}
                            >
                                    Supprimer un site
                            </Button>
                            <FormControl size="small" sx={{ mt:5, width: '150px' }}>
                                <InputLabel id="lblLang">Langue</InputLabel>
                                <Select labelId="lblLang" id="lang" name="lang" label="Langue" onChange={(e)=>{switchLang(e)}} value={lang}>
                                    <MenuItem value="de">🇩🇪 Allemand</MenuItem>
                                    <MenuItem value="en">🇬🇧 Anglais</MenuItem>
                                    <MenuItem value="fr">🇫🇷 Français</MenuItem>
                                    <MenuItem value="it">🇮🇹 Italien</MenuItem>
                                    <MenuItem value="nl">🇳🇱 Néerlandais</MenuItem>
                                </Select>
                            </FormControl>
                       </Box>
                   </Grid>
                   <Box sx={{display: 'flex'}}>
                        <Box>
                            <Box>
                                    <p>{site.address}</p>
                                    <p>{site.zipcode}</p>
                                    <p>{site.city}</p>
                                    <p>{site.country}</p>
                                </Box>
                                <Box sx={{width: '75%', mt: 6}}>
                                    <Grid component="form" item  sx={{ m: 2 }}  xs={12} md={4} onSubmit={(e)=>{updateName(e)}} noValidate>
                                        <FormControl>
                                            <TextField
                                                    id="outlined-helperText"
                                                    name="outlined-helperText"
                                                    label="Nom du site"
                                                    focused={true}
                                                    value={nameSite || ""}
                                                    placeholder="Indiquez le nom de site ici qui s'affichera dans l'application"
                                                    sx={{width: '100%'}}
                                                    onChange={(e)=>{store.dispatch(setNameSite(e.target.value))}}
                                                />                                            
                                        </FormControl>
                                        <FormControl>
                                            <Button type="submit" variant="contained" sx={{backgroundColor: 'grey', width: '100%', mt: 3}}>Enregistrer</Button>
                                        </FormControl>                                        
                                    </Grid>
                                </Box>                    
                        </Box>
                        <MapContainer center={[site.latitude, site.longitude]} zoom={13} style={{ height: "384px", width: "427px" }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={[site.latitude, site.longitude]} icon={locationIcon}>
                                <Popup>
                                {site.name}
                                </Popup>
                            </Marker>
                        </MapContainer>
                   </Box>
                   {
                       (() => {
   
                       if(params.id > 0 || site.id > 0) {
                       return(    <>
                       <h1>Les agrès du site</h1>  
   
                       <Box>
                           <Box sx={{ height: 500, width: '100%', '& .disabledRow': {bgcolor: '#e1e1e1'} }}>
                             <DataGrid localeText={{...frFR.components.MuiDataGrid.defaultProps.localeText}} sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' }, }} rows={rows} columns={actcolumns} componentsProps={{ row: {onClick: openRow } }} autoHeight disableSelectionOnClick getRowClassName={(params) => params.row.enabled ? '' : 'disabledRow'} />
                           </Box>
                       </Box>
                       </>);
                       }
                       })() 
                   }
   
                   <div>
                        <ModalDelete
                            openModal={openModalDeleteSite || openModalDeleteApparatus} 
                            handleClose={()=>{
                                store.dispatch(setOpenModalDeleteSite(false))
                                store.dispatch(setOpenModalDeleteApparatus(false))}
                            } 
                            action={async ()=>{
                                
                                if(openModalDeleteSite && !openModalDeleteApparatus){
                                    store.dispatch(setBackdrop(true))
                                    deleteSiteService(idSiteToDelete)
                                    store.dispatch(setOpenModalDeleteSite(false))
                                    store.dispatch(setIdSiteToDelete(null))
                                    navigate({pathname: "/sites" })
                                }else if(!openModalDeleteSite && openModalDeleteApparatus){
                                    store.dispatch(setBackdrop(true))
                                    store.dispatch(setOpenModalDeleteApparatus(false))
                                    await deleteApparatusService(idApparatusToDelete, params.id)
                                    const apparatuses = await getApparatusesBySite(params.id);
                                    store.dispatch(setRows(apparatuses));
                                    store.dispatch(setIdApparatusToDelete(null))
                                }else{
                                    return false
                                }
                                store.dispatch(setLoading(false))
                                store.dispatch(setBackdrop(false))
                            }} 
                            message={openModalDeleteSite && !openModalDeleteApparatus ? "Etes vous sûr(e) de vouloir supprimer le site ?" : "Etes vous sûr(e) de vouloir supprimer cet agrès ?"}
                        />
                    </div>
               </>
           );
       }
       else {
           return (
               <>
                   <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} >
                       <CircularProgress color="inherit" />
                   </Backdrop>
               </>
               );
       }
   }