import jwtDecode from "jwt-decode";

export function getCurrentUser() {
    try {
        const token = localStorage.getItem("token");

        if(!isTokenExpired(token))
        	return jwtDecode(token);
        else 
        	return null;
    } catch (error) {
        return null;
    }
}

export function logout() {
    localStorage.removeItem("token");
}

function isTokenExpired(token) {
  const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
  return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
}