import React from 'react';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { reset } from '../../api/LoginAPI';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import appconfig from "../../config/appconfig.json";
import { setError, setValues } from '../../redux/Login/LoginSlice';

export default function ResetPassword(props) {

    let navigate = useNavigate();
	const params = useParams();
	const translations = appconfig.translations[0][params.lang] ?? appconfig.translations[0]["fr"];
	const error = useSelector((state)=>state.login.error)
	const values = useSelector((state)=>state.login.values)

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
	    setValues({
	      ...values,
	      showPassword: !values.showPassword,
	    });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

    const handleReset = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        await reset(params.token, formData.get('confirm'), formData.get('password')).then((response) => {
            navigate("/reset-confirmed/" + params.lang);
        },
        error => {
            setError(translations.error_form_denied);
        });

    };

    return ( 
        <Container component="main" maxWidth="xs">
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src="/logo-actifun.png" height="80" alt="actifun"/>
                <Box component="form" onSubmit={handleReset} noValidate sx={{ mt: 1 }}>
	                <Typography variant="h5" sx={{  mb:5, mt: 5, width: '100%'}}>
	                	{translations.title}
	                </Typography>

	                { error &&
        				<Alert sx={{ mb: 4}} severity="error">{error}</Alert>
        			}

	                <FormControl sx={{ mb: 4, width: '100%'}} variant="outlined">
						<InputLabel htmlFor="password">{translations.input_password}</InputLabel>
						<OutlinedInput  name="password" id="password" label="{translations.input_password}" type={values.showPassword ? 'text' : 'password'} onChange={handleChange('password')}
						endAdornment={
						<InputAdornment position="end">
							<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
								{values.showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
						}
						/>
	    			</FormControl>
	                <FormControl sx={{ mb: 1, width: '100%'}} variant="outlined">
						<InputLabel htmlFor="confirm">{translations.input_confirm}</InputLabel>
						<OutlinedInput name="confirm" id="confirm" label="{translations.input_confirm}" type={values.showPassword ? 'text' : 'password'}  onChange={handleChange('confirm')}
						endAdornment={
						<InputAdornment position="end">
							<IconButton aria-label="toggle confirm visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
								{values.showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
						}
						/>
	    			</FormControl>      
	    			<Typography variant="subtitle2" sx={{ mb:2, mt: 2, width: '100%' }}>
	                	{translations.lbl_hint}
	                </Typography>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>{translations.input_btn}</Button>
                </Box>
            </Box>
        </Container>
        );
    }