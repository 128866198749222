import { deleteSite } from "../api/SiteAPI"
import { addMessageAlert, setOpenAlert } from "../redux/App/AppSlice"
import { store } from "../redux/Store"
import { isNotEmpty } from "./AppServices"
import messagesconfig from "../config/messagesconfig.json"

export const deleteSiteService = async (idstd) => {
    if(isNotEmpty(idstd) && !isNaN(idstd)){
        let result = await deleteSite(idstd)
        let message = ''
        let type = false
        if(result.status === 'error' || result.data.error === true){
            message = messagesconfig.SiteServices.ERROR_DELETE_SITE
            type = "error"
        }else{
            message = messagesconfig.SiteServices.SUCCESS_DELETE_SITE
            type = "success"
        }
        store.dispatch(setOpenAlert(true))
        store.dispatch(addMessageAlert({message: message, type: type}))
    }
}