import React,{useState} from 'react'
import { removeMessagesAlert } from '../redux/App/AppSlice'
import { store } from '../redux/Store'
import { Alert } from '@mui/material';

let myTimeout = setTimeout(() => {});

export default function AlertMessage({ma}) {

    const [visible, setVisible] = useState(true)
    stopTimeout() // On arrete le timer précédent (s'il y en a un)
    startTimeout() // On redémare le timer pour durer 5 secondes

    function startTimeout() {
        myTimeout = setTimeout(() => {
            store.dispatch(removeMessagesAlert())
        }, 6000)
    }

    function stopTimeout() {
        clearTimeout(myTimeout);
    }

    if(visible){
        return (
            <Alert
                severity={ma.type} 
                onClose={()=>{
                    stopTimeout()
                    setVisible(false)
                }}
                sx={{ zIndex: 2, color: "#fff", backgroundColor: ma.type === 'error' ? "rgb(211, 47, 47)" : "rgb(56, 142, 60)"}}>
                    {ma.message}
            </Alert>
        )        
    }
    return <></>

}